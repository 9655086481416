<template>
    <template v-if="mode == modes.detail">
        <label class="result__calendar-open" v-on:click="showCalendar">空室カレンダー</label>
        <template v-if="show">
            <div class="vdp-datepicker adjust-datepicker result__input">
                <div></div>
                <div class="vdp-datepicker__calendar">
                    <!-- カレンダーヘッダ -->
                    <header>
                        <span :class="{ prev: true, disabled: prevDisabled }" v-on:click="setLastMonth">＜</span>
                        <span class="day__month_btn">{{ year }}年{{ month }}月</span>
                        <span :class="{ next: true, disabled: nextDisabled }" v-on:click="setNextMonth">＞</span>
                    </header>
                    <div>
                        <!-- 曜日 -->
                        <template v-for="(dayname, index) in weekdays" :key="index">
                            <span class="cell day-header">{{ dayname }}</span>
                        </template>
                        <!-- 日付 -->
                        <template v-for="(data, wIndex) in calendarData" :key="wIndex">
                            <span :class="calendarClass(data)" v-on:click="dateClick(data.dep_date, data.empty_status)">{{ data.day }}</span>
                        </template>
                    </div>
                    <div id="calender-set__buttons" class="calender-set__buttons calender-set__buttons-end">
                        <button id="btn-calendar-close" class="btn btn-calendar-close input-set__cansel" v-on:click="hideCalendar">閉じる</button>
                        <span style="font-size: x-small; padding: 0px 1rem">○ 空室有&nbsp;○✕ 予約不可&nbsp;▢ 運休&nbsp;✕ 欠航</span>
                    </div>
                </div>
            </div>
        </template>
    </template>
    <template v-else>
        <div class="vdp-datepicker adjust-datepicker datepicker-close">
            <div class>
                <input
                    type="text"
                    class="search__input input-search input-search-date input-search-date-little"
                    name="search__input-date"
                    id="search__input-date"
                    placeholder="乗船日を変更する"
                    clear-button="false"
                    readonly="true"
                    autocomplete="off"
                    v-on:click="showCalendar"
                />
            </div>
            <template v-if="show">
                <div class="vdp-datepicker__calendar">
                    <!-- カレンダーヘッダ -->
                    <header>
                        <span :class="{ prev: true, disabled: prevDisabled }" v-on:click="setLastMonth">＜</span>
                        <span class="day__month_btn">{{ year }}年{{ month }}月</span>
                        <span :class="{ next: true, disabled: nextDisabled }" v-on:click="setNextMonth">＞</span>
                    </header>
                    <div>
                        <!-- 曜日 -->
                        <template v-for="(dayname, index) in weekdays" :key="index">
                            <span class="cell day-header">{{ dayname }}</span>
                        </template>
                        <!-- 日付 -->
                        <template v-for="(data, wIndex) in calendarData" :key="wIndex">
                            <span :class="calendarClass(data)" v-on:click="dateClick(data.dep_date, data.empty_status)">{{ data.day }}</span>
                        </template>
                    </div>
                    <div id="calender-set__buttons" class="calender-set__buttons calender-set__buttons-end">
                        <button id="btn-calendar-close" class="btn btn-calendar-close input-set__cansel" v-on:click="hideCalendar">閉じる</button>
                        <span style="font-size: x-small; padding: 0px 1rem">○ 空室有&nbsp;○✕ 予約不可&nbsp;▢ 運休&nbsp;✕ 欠航</span>
                    </div>
                </div>
            </template>
        </div>
    </template>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, watch, onMounted } from 'vue'
import { DEP_STATUS_NORMAL, DEP_STATUS_DOCK } from '@/lib/constants'
import { API_ORDER, isNull, formatDate, nextMonth, castStringToDate } from '@/lib/util'
import { AxiosResponse, AxiosError } from 'axios'
import apiClient from '@/plugins/axioswrap'

export default defineComponent({
    name: 'EmptyCalendar',
    emits: ['update:modelValue', 'selectDate', 'openCalendar'],
    props: [
        'modelValue',
        'mode',
        'sea_route_code',
        'seat_type',
        'contract_number',
        'cabin_seat_count',
        'car_count',
        'bicycle_count',
        'contract_start_date',
        'contract_end_date',
    ],

    setup(props, ctx) {
        /**
         * data
         */
        const modes = {
            detail: 1,
            change: 2,
        }
        const weekdays = ['日', '月', '火', '水', '木', '金', '土']
        const year: Ref<number> = ref(2020)
        const month: Ref<any> = ref(null)
        const today: Ref<any> = ref(null)
        const calendarData: Ref<any[]> = ref([])
        const show: Ref<boolean> = ref(false)

        /**
         * computed
         */
        /** ローカル変更用の値 */
        const localValue = computed<any>({
            get() {
                return props.modelValue
            },
            set(newVal: any) {
                ctx.emit('update:modelValue', newVal)
            },
        })
        /**
         * 先月選択ボタンの制御（現在より前の月は表示しない）
         */
        const prevDisabled = computed(() => {
            const date = new Date()
            const y = date.getFullYear()
            const m = date.getMonth() + 1

            return y == year.value && m == month.value
        })
        /**
         * 翌月選択ボタンの制御（6ヶ月先までは表示）
         */
        const nextDisabled = computed(() => {
            const date = new Date()
            const maxDate = new Date(date.setMonth(date.getMonth() + 6))
            const y = maxDate.getFullYear()
            const m = maxDate.getMonth() + 1

            return y == year.value && m == month.value
        })

        /**
         * watch
         */
        watch(month, () => {
            if (show.value) {
                createCalendar()
                getCalendarStatus()
            }
        })

        /**
         * methods
         */

        /**
         * 対象月のカレンダーを作成
         */
        const createCalendar = () => {
            console.log(year.value + '-' + month.value + 'のデータ作成')
            calendarData.value = []

            // 初日の曜日を取得
            var firstDay = castStringToDate(`${year.value}-${month.value}`)!
            const firstWeekDay = firstDay.getDay()
            // 月の日数
            const lastDay = new Date(year.value, month.value, 0)

            for (var i = 0; i < firstWeekDay; i++) {
                calendarData.value.push({
                    day: null,
                    dep_date: null,
                    dep_status: null,
                    empty_status: null,
                    ship_schedule_id: null,
                })
            }
            while (firstDay <= lastDay) {
                calendarData.value.push({
                    day: firstDay.getDate(),
                    dep_date: castStringToDate(formatDate(firstDay)),
                    dep_status: null,
                    empty_status: null,
                    ship_schedule_id: null,
                })
                firstDay.setDate(firstDay.getDate() + 1)
            }
            return calendarData.value
        }

        /**
         * カレンダーAPIをCall
         * @param tmpPostData
         * @param startDate
         * @param endDate
         * @param setIrregular
         */
        const postCalendar = async (tmpPostData: any, startDate: string, endDate: string, setIrregular = false): Promise<boolean> => {
            console.log('↓↓↓ EmptyCalendar.postCalendar ↓↓↓')
            const postData = {
                contract_number: tmpPostData.contract_number,
                seat_type: tmpPostData.seat_type,
                sea_route_code: props.sea_route_code,
                start_date: startDate,
                end_date: endDate,
                cabin_seat_count: tmpPostData.cabin_seat_count,
                car_count: tmpPostData.car_count,
                bicycle_count: tmpPostData.bicycle_count,
            }
            return await apiClient
                .post(`${API_ORDER}/calendar`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    if (data.length > 0) {
                        // データが存在する場合
                        return data
                    } else {
                        return []
                    }
                })
                .catch((e: AxiosError<{ error: string }>) => {
                    // エラー処理
                    console.log(e.message)
                    return []
                })
                .finally(() => {
                    console.log('↑↑↑ EmptyCalendar.postCalendar ↑↑↑')
                })
        }

        // カレンダー表示
        const getCalendarStatus = async () => {
            // 表示年月の1日を取得
            let display_year_month_one = castStringToDate(`${year.value}-${month.value}-01 0:00:00`)
            let startDate = formatDate(display_year_month_one)
            let endDate = formatDate(nextMonth(display_year_month_one!))
            let callApis = []

            // プラン指定
            const postData = {
                contract_number: props.contract_number,
                seat_type: props.seat_type,
                sea_route_code: props.sea_route_code,
                start_date: startDate,
                end_date: endDate,
                cabin_seat_count: props.cabin_seat_count,
                car_count: props.car_count,
                bicycle_count: props.bicycle_count,
            }
            if (display_year_month_one!.getFullYear() == new Date().getFullYear() && display_year_month_one!.getMonth() == new Date().getMonth()) {
                /**
                 * 過去日付は参照しない
                 */
                let fromDate = new Date()
                let toDate = new Date()
                const eom = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0)

                for (let i = 0; i < 8; i++) {
                    toDate.setDate(toDate.getDate() + 4)
                    let frDay = ('0' + fromDate.getDate()).slice(-2)
                    let toDay = ('0' + toDate.getDate()).slice(-2)

                    if (toDate.getMonth() > eom.getMonth()) {
                        toDay = ('0' + eom.getDate()).slice(-2)
                    }
                    callApis.push(
                        new Promise((resolve) => {
                            const result = postCalendar(
                                postData,
                                formatDate(castStringToDate(`${year.value}-${month.value}-${frDay}`)),
                                formatDate(castStringToDate(`${year.value}-${month.value}-${toDay}`)),
                                true,
                            )
                            resolve(result)
                        }),
                    )
                    fromDate.setDate(fromDate.getDate() + 5)
                    toDate.setDate(toDate.getDate() + 1)

                    if (fromDate.getMonth() > eom.getMonth()) {
                        break
                    }
                }
            } else {
                // 1ヶ月分APIを呼ぶ
                const year_month = `${year.value}-${month.value}`
                let fromDate = castStringToDate(year_month + '-01')
                const eom = formatDate(new Date(fromDate!.getFullYear(), fromDate!.getMonth() + 1, 0))

                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(
                            postData,
                            formatDate(castStringToDate(`${year.value}-${month.value}-01`)),
                            formatDate(castStringToDate(`${year.value}-${month.value}-05`)),
                            true,
                        )
                        resolve(data)
                    }),
                )
                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(
                            postData,
                            formatDate(castStringToDate(`${year.value}-${month.value}-06`)),
                            formatDate(castStringToDate(`${year.value}-${month.value}-10`)),
                            true,
                        )
                        resolve(data)
                    }),
                )
                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(
                            postData,
                            formatDate(castStringToDate(`${year.value}-${month.value}-11`)),
                            formatDate(castStringToDate(`${year.value}-${month.value}-15`)),
                            true,
                        )
                        resolve(data)
                    }),
                )
                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(
                            postData,
                            formatDate(castStringToDate(`${year.value}-${month.value}-16`)),
                            formatDate(castStringToDate(`${year.value}-${month.value}-20`)),
                            true,
                        )
                        resolve(data)
                    }),
                )
                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(
                            postData,
                            formatDate(castStringToDate(`${year.value}-${month.value}-21`)),
                            formatDate(castStringToDate(`${year.value}-${month.value}-25`)),
                            true,
                        )
                        resolve(data)
                    }),
                )
                callApis.push(
                    new Promise((resolve) => {
                        const data = postCalendar(postData, formatDate(castStringToDate(`${year.value}-${month.value}-26`)), eom, true)
                        resolve(data)
                    }),
                )
            }

            await Promise.all(callApis).then((res) => {
                res.forEach((datas: any) => {
                    setCalendarStatus(datas)
                })
                console.log('calendar data complete!')
            })
        }

        /**
         * カレンダーの空き容共を設定
         */
        const setCalendarStatus = (info: any[]) => {
            // カレンダーデータ作成中の場合
            if (calendarData.value.length == 0) {
                setTimeout(() => {
                    setCalendarStatus(info)
                }, 500)
                return
            }

            info.forEach((elm) => {
                calendarData.value
                    .filter((x) => formatDate(x.dep_date) == elm.dep_date)
                    .forEach((y) => {
                        y.dep_status = elm.dep_status
                        y.empty_status = elm.empty_status
                        y.ship_schedule_id = elm.ship_schedule_id
                    })
            })
        }

        /**
         * 先月のカレンダーを取得
         */
        const setLastMonth = () => {
            if (prevDisabled.value) {
                return
            }

            if (month.value === 1) {
                year.value -= 1
                month.value = 12
            } else {
                month.value -= 1
            }
        }

        /**
         * 翌月のカレンダーを取得
         */
        const setNextMonth = () => {
            if (nextDisabled.value) {
                return
            }

            if (month.value === 12) {
                year.value += 1
                month.value = 1
            } else {
                month.value += 1
            }
        }

        /**
         * カレンダー日付クリック時の処理
         * @param depDate
         */
        const dateClick = (depDate: any, emptyStatus: number | null) => {
            if (depDate == '' || depDate == null || emptyStatus == null || emptyStatus < 1) {
                return
            }
            localValue.value = depDate
            hideCalendar()
            if (props.mode == modes.detail) {
                ctx.emit('selectDate')
            }
        }

        /**
         * クラス情報の設定
         * @param dateInfo
         */
        const calendarClass = (dateInfo: any) => {
            const date = new Date()
            date.setHours(0, 0, 0, 0)

            let ret = 'cell day '

            if (dateInfo.day == null) {
                ret += 'blank '
            }

            if (dateInfo.dep_date < date) {
                ret += 'disabled '
            } else if (!isNull(props.contract_start_date) && props.contract_start_date > date) {
                ret += 'disabled '
            } else if (!isNull(props.contract_end_date) && props.contract_end_date < date) {
                ret += 'disabled '
            } else {
                if (localValue.value && formatDate(localValue.value) == formatDate(dateInfo.dep_date)) {
                    // 選択された日付の場合
                    ret += 'selected '
                } else {
                    if (dateInfo.empty_status == null) {
                        ret += 'disabled calendar-fully-occupied '
                    } else if (dateInfo.dep_status == DEP_STATUS_NORMAL) {
                        // 通常
                        if (dateInfo.empty_status <= 0) {
                            // 満席
                            ret += 'disabled calendar-fully-occupied '
                        }
                    } else if (dateInfo.dep_status == DEP_STATUS_DOCK) {
                        // ドック
                        ret += 'disabled calendar-dock '
                    } else {
                        // 欠航
                        ret += 'disabled calendar-cancel-etc '
                    }
                }
            }

            return ret
        }

        /**
         * カレンダー表示
         */
        const showCalendar = () => {
            if (show.value) {
                return
            }
            if (localValue.value) {
                // 日付がある場合、年月を変更
                year.value = localValue.value.getFullYear()
                month.value = localValue.value.getMonth() + 1
            }
            show.value = true
            createCalendar()
            getCalendarStatus()
        }

        /**
         * カレンダー非表示
         */
        const hideCalendar = () => {
            show.value = false
        }

        /**
         * 初期化
         */
        const initialize = () => {
            const date = new Date()
            const d = ('0' + date.getDate()).slice(-2)
            year.value = date.getFullYear()
            month.value = date.getMonth() + 1

            today.value = year.value + '-' + ('0' + month.value).slice(-2) + '-' + d
        }

        /**
         * onMounted
         */
        onMounted(() => {
            hideCalendar()
            initialize()
        })

        return {
            modes,
            weekdays,
            year,
            month,
            today,
            calendarData,
            show,

            localValue,
            prevDisabled,
            nextDisabled,

            createCalendar,
            setLastMonth,
            setNextMonth,
            calendarClass,
            dateClick,
            showCalendar,
            hideCalendar,
        }
    },
})
</script>

<style scoped>
.rtl {
    direction: rtl;
}

.vdp-datepicker {
    position: relative;
    text-align: left;
}

.vdp-datepicker * {
    box-sizing: border-box;
}

.vdp-datepicker__calendar {
    position: absolute;
    z-index: 100;
    background: #fff;
    width: 300px;
    border: 1px solid #ccc;
}

.vdp-datepicker__calendar header {
    display: block;
    line-height: 40px;
}

.vdp-datepicker__calendar header span {
    display: inline-block;
    text-align: center;
    width: 71.42857142857143%;
    float: left;
}

.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next {
    width: 14.285714285714286%;
    float: left;
    text-indent: -10000px;
    position: relative;
}

.vdp-datepicker__calendar header .prev:after,
.vdp-datepicker__calendar header .next:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 6px solid transparent;
}

.vdp-datepicker__calendar header .prev:after {
    border-right: 10px solid #000;
    margin-left: -5px;
}

.vdp-datepicker__calendar header .prev.disabled:after {
    border-right: 10px solid #ddd;
}

.vdp-datepicker__calendar header .next:after {
    border-left: 10px solid #000;
    margin-left: 5px;
}

.vdp-datepicker__calendar header .next.disabled:after {
    border-left: 10px solid #ddd;
}

.vdp-datepicker__calendar header .prev:not(.disabled),
.vdp-datepicker__calendar header .next:not(.disabled),
.vdp-datepicker__calendar header .up:not(.disabled) {
    cursor: pointer;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
    background: #eee;
}

.vdp-datepicker__calendar .disabled {
    color: #ddd;
    cursor: default;
}

.vdp-datepicker__calendar .flex-rtl {
    display: flex;
    width: inherit;
    flex-wrap: wrap;
}

.vdp-datepicker__calendar .cell {
    display: inline-block;
    padding: 0 5px;
    width: 14.285714285714286%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year {
    cursor: pointer;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #4bd;
}

.vdp-datepicker__calendar .cell.selected {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.selected:hover {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.selected.highlighted {
    background: #4bd;
}

.vdp-datepicker__calendar .cell.highlighted {
    background: #cae5ed;
}

.vdp-datepicker__calendar .cell.highlighted.disabled {
    color: #a3a3a3;
}

.vdp-datepicker__calendar .cell.grey {
    color: #888;
}

.vdp-datepicker__calendar .cell.grey:hover {
    background: inherit;
}

.vdp-datepicker__calendar .cell.day-header {
    font-size: 75%;
    white-space: nowrap;
    cursor: inherit;
}

.vdp-datepicker__calendar .cell.day-header:hover {
    background: inherit;
}

.vdp-datepicker__calendar .month,
.vdp-datepicker__calendar .year {
    width: 33.333%;
}

.vdp-datepicker__clear-button,
.vdp-datepicker__calendar-button {
    cursor: pointer;
    font-style: normal;
}

.vdp-datepicker__clear-button.disabled,
.vdp-datepicker__calendar-button.disabled {
    color: #999;
    cursor: default;
}
</style>
