import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-75cd2aa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "calendar-picker",
  class: "vdp-datepicker adjust-datepicker datepicker-close-main"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  id: "calendar-picker__calendar",
  class: "vdp-datepicker__calendar"
}
const _hoisted_4 = { class: "day__month_btn" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "calender-set__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "search__input input-search input-search-date",
        name: "search__input-date",
        id: "search__input-date",
        placeholder: _ctx.placeholder,
        "clear-button": "false",
        readonly: "true",
        autocomplete: "off",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formatDate) = $event))
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.formatDate]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", null, [
        _createElementVNode("span", {
          class: _normalizeClass({ prev: true, disabled: _ctx.prevDisabled }),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setLastMonth && _ctx.setLastMonth(...args)))
        }, "＜", 2),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.year) + "年" + _toDisplayString(_ctx.month) + "月", 1),
        _createElementVNode("span", {
          class: _normalizeClass({ next: true, disabled: _ctx.nextDisabled }),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setNextMonth && _ctx.setNextMonth(...args)))
        }, "＞", 2)
      ]),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekdays, (dayname, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: "cell day-header"
          }, _toDisplayString(dayname), 1))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarData, (data, wIndex) => {
          return (_openBlock(), _createElementBlock("span", {
            key: wIndex,
            class: _normalizeClass(_ctx.calendarClass(data)),
            onClick: ($event: any) => (_ctx.dateClick(data.dep_date))
          }, _toDisplayString(data.day), 11, _hoisted_5))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          id: "btn-calendar-noday",
          class: "btn btn-calendar-noday input-set__cansel",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.unspecifiedDate && _ctx.unspecifiedDate(...args)))
        }, "日付指定なし"),
        _createElementVNode("button", {
          id: "btn-calendar-close",
          class: "btn btn-calendar-close input-set__cansel",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.hideCalendar && _ctx.hideCalendar(...args)))
        }, "閉じる")
      ])
    ])
  ]))
}