<!-- eslint-disable no-irregular-whitespace -->
<template>
    <!-- title -->
    <section class="title">
        <div class="title__container">
            <h1 class="title__name">お支払い情報のご選択</h1>
        </div>
    </section>
    <!-- /title -->
    <main id="main" class="main">
        <!-- form -->
        <section class="form">
            <div class="form__container">
                <!-- form__list -->
                <ul id="form__list" class="form__list">
                    <li id="form__item-payment" class="form__item form__item-payment select-none">
                        <div class="form__item-header">
                            <h3 class="form__item-name">お支払い情報</h3>
                        </div>
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-payment">
                                        <!-- payment -->
                                        <div id="input__item-payment" class="input__item input__item-payment">
                                            <dt id="input__header-payment" class="input__header input__header-payment">
                                                <label id="input__name-payment" class="input__name input__name-payment" for=""
                                                    >お支払い情報を選択してください</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-payment" class="input__container input__container-payment">
                                                <div
                                                    v-show="canCredit"
                                                    id="input__radio-payment-card"
                                                    class="input__radio input__radio-payment input__radio-payment-card"
                                                >
                                                    <input
                                                        id="input__payment-card"
                                                        class="input__radio-btn input__payment input__payment-card"
                                                        type="radio"
                                                        name="input__payment"
                                                        v-model="authori_payment_method"
                                                        :value="PAYMENT_CARD"
                                                    />
                                                    <label
                                                        id="input__label-payment-card"
                                                        class="input__radio-label input__label-payment-card"
                                                        for="input__payment-card"
                                                        >クレジットカード</label
                                                    >
                                                </div>
                                                <div
                                                    v-show="canCvsPayeasy"
                                                    id="input__radio-payment-payeasy"
                                                    class="input__radio input__radio-payment input__radio-payment-payeasy"
                                                >
                                                    <input
                                                        id="input__payment-payeasy"
                                                        class="input__radio-btn input__payment input__payment-payeasy"
                                                        type="radio"
                                                        name="input__payment"
                                                        v-model="authori_payment_method"
                                                        :value="PAYMENT_PAYEASY"
                                                    />
                                                    <label
                                                        id="input__label-payment-payeasy"
                                                        class="input__radio-label input__label-payment-payeasy"
                                                        for="input__payment-payeasy"
                                                        >Pay-easy</label
                                                    >
                                                </div>
                                                <div
                                                    v-show="canCvsPayeasy"
                                                    id="input__radio-payment-conveni"
                                                    class="input__radio input__radio-payment input__radio-payment-conveni"
                                                >
                                                    <input
                                                        id="input__payment-conveni"
                                                        class="input__radio-btn input__payment input__payment-conveni"
                                                        type="radio"
                                                        name="input__payment"
                                                        v-model="authori_payment_method"
                                                        :value="PAYMENT_CONVENI"
                                                    />
                                                    <label
                                                        id="input__label-payment-conveni"
                                                        class="input__radio-label input__label-payment-conveni"
                                                        for="input__payment-conveni"
                                                        >コンビニ決済</label
                                                    >
                                                </div>
                                                <div
                                                    v-show="canOnTheDay"
                                                    id="input__radio-payment-counter"
                                                    class="input__radio input__radio-payment input__radio-payment-counter"
                                                >
                                                    <input
                                                        id="input__payment-counter"
                                                        class="input__radio-btn input__payment input__payment-counter"
                                                        type="radio"
                                                        name="input__payment"
                                                        v-model="authori_payment_method"
                                                        :value="PAYMENT_COUNTER"
                                                    />
                                                    <label
                                                        id="input__label-payment-counter"
                                                        class="input__radio-label input__label-payment-counter"
                                                        for="input__payment-counter"
                                                        >当日決済</label
                                                    >
                                                </div>
                                            </dd>
                                            <p class="input__error-text">お支払い情報を選択してください</p>
                                            <p class="input__attention">
                                                予約完了後は、支払方法の変更はできません。また、予約変更時も、支払方法の変更はできません。
                                            </p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line input__line-logo" v-show="isNull(authori_payment_method)">
                                        <!-- logo -->
                                        <div id="input__item-logo" class="input__item input__item-logo">
                                            <dt id="input__header-logo" class="input__header input__header-logo">
                                                <label id="input__name-logo" class="input__name input__name-logo" for=""
                                                    >利用できるお支払い情報</label
                                                >
                                            </dt>
                                            <dd id="input__container-logo" class="input__container input__container-logo">
                                                <ul class="payment__list">
                                                    <li class="payment__item" v-show="canCredit">
                                                        <img src="@/images/settlement/card-visa.png" style="height: 2.8rem" alt="VISA" />
                                                    </li>
                                                    <li class="payment__item" v-show="canCredit">
                                                        <img src="@/images/settlement/card-master.png" style="height: 4.2rem" alt="MASTER" />
                                                    </li>
                                                    <li class="payment__item" v-show="canCredit">
                                                        <img src="@/images/settlement/card-jcb.png" alt="JCB" />
                                                    </li>
                                                    <!-- <li class="payment__item"><img src="@/images/settlement/card-diners.png" alt="DINERS"></li> -->
                                                    <li class="payment__item" v-show="canCredit">
                                                        <img src="@/images/settlement/card-amex.png" style="height: 4.2rem" alt="AMEX" />
                                                    </li>
                                                    <li class="payment__item" v-show="canCvsPayeasy">
                                                        <img src="@/images/settlement/ebank-payeasy.png" style="height: 4.2rem" alt="Pay-easy" />
                                                    </li>
                                                    <li class="payment__item none" v-show="canCvsPayeasy">
                                                        <img src="@/images/settlement/conveni-seveneleven.png" alt="セブンイレブン" />
                                                    </li>
                                                    <li class="payment__item" v-show="canCvsPayeasy">
                                                        <img src="@/images/settlement/conveni-lawson.png" style="height: 2rem" alt="ローソン" />
                                                    </li>
                                                    <li class="payment__item" v-show="canCvsPayeasy">
                                                        <img
                                                            src="@/images/settlement/conveni-familymart.png"
                                                            style="height: 10rem"
                                                            alt="ファミリーマート"
                                                        />
                                                    </li>
                                                    <li class="payment__item" v-show="canCvsPayeasy">
                                                        <img src="@/images/settlement/conveni-ministop.png" alt="ミニストップ" />
                                                    </li>
                                                </ul>
                                            </dd>
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-card" v-show="authori_payment_method == PAYMENT_CARD">
                                        <!-- card -->
                                        <div id="input__item-card" class="input__item input__item-card">
                                            <dt id="input__header-card" class="input__header input__header-card">
                                                <label id="input__name-card" class="input__name input__name-card" for="">クレジットカード</label>
                                            </dt>
                                            <dd id="input__container-card" class="input__container input__container-card">
                                                <ul class="payment__list">
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/card-visa.png" style="height: 2.8rem" alt="VISA" />
                                                    </li>
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/card-master.png" style="height: 4.2rem" alt="MASTER" />
                                                    </li>
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/card-jcb.png" alt="JCB" />
                                                    </li>
                                                    <!-- <li class="payment__item"><img src="@/images/settlement/card-diners.png" alt="DINERS"></li> -->
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/card-amex.png" style="height: 4.2rem" alt="AMEX" />
                                                    </li>
                                                </ul>
                                            </dd>
                                            <p class="input__text">※ご利用可能なクレジットカードは、VISA、Master、JCB、AMEXとなります。</p>
                                            <p class="input__text">
                                                ※3Dセキュア2.0に対応したカードのみご利用いただけます。詳しくは
                                                <a target="_blank" href="https://www.miyazakicarferry.com/webcredit-3dsecure/">こちら</a>
                                            </p>
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-cardno" v-show="authori_payment_method == PAYMENT_CARD">
                                        <!-- cardno -->
                                        <div id="input__item-cardno" class="input__item input__item-cardno">
                                            <dt id="input__header-cardno" class="input__header input__header-cardno">
                                                <label id="input__name-cardno" class="input__name input__name-cardno" for="input__cardno01"
                                                    >カード番号</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-cardno" class="input__container input__container-cardno">
                                                <input
                                                    id="input__cardno"
                                                    class="input__form input__cardno"
                                                    name="input__cardno"
                                                    type="text"
                                                    maxlength="16"
                                                    v-model="card_no"
                                                    v-on:blur="blurCardNo"
                                                />
                                                <!-- <input id="input__cardno01" class="input__form input__cardno01" name="input__cardno01" type="text" maxlength="4" v-model="card_no_1">
                        <span>-</span>
                        <input id="input__cardno02" class="input__form input__cardno02" name="input__cardno02" type="text" maxlength="6" v-model="card_no_2">
                        <span>-</span>
                        <input id="input__cardno03" class="input__form input__cardno03" name="input__cardno03" type="text" maxlength="5" v-model="card_no_3">
                        <span>-</span>
                        <input id="input__cardno04" class="input__form input__cardno04" name="input__cardno04" type="text" maxlength="4" v-model="card_no_4"> -->
                                            </dd>
                                            <p class="input__text">※ハイフン、スペース不要</p>
                                            <p class="input__error-text">カード番号を半角数字で入力してください</p>
                                        </div>
                                    </div>
                                    <!-- line -->
                                    <div class="input__line input__line-expiry" v-show="authori_payment_method == PAYMENT_CARD">
                                        <!-- expiry -->
                                        <div id="input__item-expiry" class="input__item input__item-expiry">
                                            <dt id="input__header-expiry" class="input__header input__header-expiry">
                                                <label id="input__name-expiry" class="input__name input__name-expiry" for="input__expiry-year"
                                                    >有効期限</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-expiry" class="input__container input__container-expiry">
                                                <select
                                                    id="input__expiry-month"
                                                    class="input__select input__expiry input__expiry-month"
                                                    name="input__expiry-month"
                                                    v-model="month"
                                                >
                                                    <option value="">--</option>
                                                    <option value="01">1</option>
                                                    <option value="02">2</option>
                                                    <option value="03">3</option>
                                                    <option value="04">4</option>
                                                    <option value="05">5</option>
                                                    <option value="06">6</option>
                                                    <option value="07">7</option>
                                                    <option value="08">8</option>
                                                    <option value="09">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                                <span>月</span>
                                                <select
                                                    id="input__expiry-year"
                                                    class="input__select input__expiry input__expiry-year"
                                                    name="input__expiry-year"
                                                    v-model="year"
                                                >
                                                    <template v-for="(expiry_year, idx) in expiryYears" :key="idx">
                                                        <option :value="expiry_year.value">
                                                            {{ expiry_year.text }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <span>年</span>
                                            </dd>
                                            <p class="input__error-text">有効期限を選択してください</p>
                                        </div>
                                        <!-- security -->
                                        <div id="input__item-security" class="input__item input__item-security">
                                            <dt id="input__header-security" class="input__header input__header-security">
                                                <label id="input__name-security" class="input__name input__name-security" for=""
                                                    >セキュリティコード</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-security" class="input__container input__container-security">
                                                <input
                                                    id="input__security"
                                                    class="input__form input__security"
                                                    type="text"
                                                    maxlength="4"
                                                    name="input__security"
                                                    v-model="security_no"
                                                />
                                            </dd>
                                            <p class="input__error-text">セキュリティコードを半角数字で入力してください</p>
                                        </div>
                                        <div id="input__item-explain-car" class="input__item-explain input__item-explain-card">
                                            <img
                                                class=""
                                                src="@/images/items/card-back.svg"
                                                width="201"
                                                height="82"
                                                alt="セキュリティコード説明画像"
                                            />
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-cardname" v-show="authori_payment_method == PAYMENT_CARD">
                                        <!-- cardname -->
                                        <div id="input__item-cardname" class="input__item input__item-cardname">
                                            <dt id="input__header-cardname" class="input__header input__header-cardname">
                                                <label id="input__name-cardname" class="input__name input__name-cardname" for="input__cardname"
                                                    >カード名義人</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-cardname" class="input__container input__container-cardname">
                                                <input
                                                    id="input__cardname"
                                                    class="input__form input__cardname"
                                                    name="input__cardname"
                                                    type="text"
                                                    v-model="card_name"
                                                />
                                            </dd>
                                            <p class="input__error-text">カード名義人を半角英字で入力してください</p>
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-payeasy" v-show="authori_payment_method == PAYMENT_PAYEASY">
                                        <!-- payeasy -->
                                        <div id="input__item-payeasy" class="input__item input__item-payeasy">
                                            <dt id="input__header-payeasy" class="input__header input__header-payeasy">
                                                <label id="input__name-payeasy" class="input__name input__name-payeasy" for="">Pay-easy</label>
                                            </dt>
                                            <dd id="input__container-payeasy" class="input__container input__container-payeasy">
                                                <ul class="payment__list">
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/ebank-payeasy.png" style="height: 4.2rem" alt="Pay-easy" />
                                                    </li>
                                                </ul>
                                            </dd>
                                            <p class="input__text">
                                                Pay-easy（ペイジー）とは、各種料金を各種金融機関のATM・ネットバンキングでお支払いできるサービスです。<br />
                                                利用可能なATMには「Pay-easy」マークが表示されています。
                                            </p>
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-conveni" v-show="authori_payment_method == PAYMENT_CONVENI">
                                        <!-- conveni -->
                                        <div id="input__item-conveni" class="input__item input__item-conveni">
                                            <dt id="input__header-conveni" class="input__header input__header-conveni">
                                                <label id="input__name-conveni" class="input__name input__name-conveni" for=""
                                                    >決済するコンビニを選択してください</label
                                                >
                                                <span class="input__required">必須</span>
                                            </dt>
                                            <dd id="input__container-conveni" class="input__container input__container-conveni">
                                                <ul class="payment__list">
                                                    <li class="payment__item none">
                                                        <img
                                                            src="@/images/settlement/conveni-seveneleven.png"
                                                            width="72"
                                                            height="70"
                                                            alt="セブンイレブン"
                                                        />
                                                    </li>
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/conveni-lawson.png" style="height: 2rem" alt="ローソン" />
                                                    </li>
                                                    <li class="payment__item">
                                                        <img
                                                            src="@/images/settlement/conveni-familymart.png"
                                                            style="height: 10rem"
                                                            alt="ファミリーマート"
                                                        />
                                                    </li>
                                                    <li class="payment__item">
                                                        <img src="@/images/settlement/conveni-ministop.png" alt="ミニストップ" />
                                                    </li>
                                                </ul>
                                            </dd>
                                            <dd id="input__container-conveni" class="input__container input__container-conveni">
                                                <div
                                                    id="input__radio-conveni-seven"
                                                    class="input__radio input__radio-conveni input__radio-conveni-seven none"
                                                >
                                                    <input
                                                        id="input__conveni-seven"
                                                        class="input__radio-btn input__conveni input__conveni-seven"
                                                        type="radio"
                                                        name="input__conveni"
                                                        v-model="convenience_type"
                                                        :value="CONVENIENCE_TYPE_SEVEN_ELEVEN"
                                                    />
                                                    <label
                                                        id="input__label-conveni-seven"
                                                        class="input__radio-label input__label-conveni-seven"
                                                        for="input__conveni-seven"
                                                        >セブンイレブン</label
                                                    >
                                                </div>
                                                <div
                                                    id="input__radio-conveni-lowson"
                                                    class="input__radio input__radio-conveni input__radio-conveni-lowson"
                                                >
                                                    <input
                                                        id="input__conveni-lowson"
                                                        class="input__radio-btn input__conveni input__conveni-lowson"
                                                        type="radio"
                                                        name="input__conveni"
                                                        v-model="convenience_type"
                                                        :value="CONVENIENCE_TYPE_LAWSON"
                                                    />
                                                    <label
                                                        id="input__label-conveni-lowson"
                                                        class="input__radio-label input__label-conveni-lowson"
                                                        for="input__conveni-lowson"
                                                        >ローソン</label
                                                    >
                                                </div>
                                                <div
                                                    id="input__radio-conveni-famima"
                                                    class="input__radio input__radio-conveni input__radio-conveni-famima"
                                                >
                                                    <input
                                                        id="input__conveni-famima"
                                                        class="input__radio-btn input__conveni input__conveni-famima"
                                                        type="radio"
                                                        name="input__conveni"
                                                        v-model="convenience_type"
                                                        :value="CONVENIENCE_TYPE_FAMILY_MART"
                                                    />
                                                    <label
                                                        id="input__label-conveni-famima"
                                                        class="input__radio-label input__label-conveni-famima"
                                                        for="input__conveni-famima"
                                                        >ファミリーマート</label
                                                    >
                                                </div>
                                                <div
                                                    id="input__radio-conveni-ministop"
                                                    class="input__radio input__radio-conveni input__radio-conveni-ministop"
                                                >
                                                    <input
                                                        id="input__conveni-ministop"
                                                        class="input__radio-btn input__conveni input__conveni-ministop"
                                                        type="radio"
                                                        name="input__conveni"
                                                        v-model="convenience_type"
                                                        :value="CONVENIENCE_TYPE_MINI_STOP"
                                                    />
                                                    <label
                                                        id="input__label-conveni-ministop"
                                                        class="input__radio-label input__label-conveni-ministop"
                                                        for="input__conveni-ministop"
                                                        >ミニストップ</label
                                                    >
                                                </div>
                                            </dd>
                                            <p class="input__error-text">決済するコンビニを選択してください</p>
                                            <p class="input__attention">
                                                予約完了後は、決済するコンビニの変更はできません。また、予約変更時も、決済するコンビニの変更はできません。
                                            </p>
                                        </div>
                                    </div>

                                    <!-- line -->
                                    <div class="input__line input__line-limit" v-show="authori_payment_method != PAYMENT_COUNTER">
                                        <!-- limit -->
                                        <div id="input__item-limit" class="input__item input__item-limit">
                                            <dt id="input__header-limit" class="input__header input__header-limit">
                                                <label id="input__name-limit" class="input__name input__name-limit" for="">入金期限ルール</label>
                                            </dt>
                                            <dd id="input__container-limit" class="input__container input__container-limit">
                                                <div id="input__checkbox-limit" class="input__checkbox input__checkbox-limit">
                                                    <input
                                                        id="input__limit"
                                                        class="input__checkbox-btn input__limit"
                                                        type="checkbox"
                                                        name="input__limit"
                                                        v-model="agreement_rule_convenience"
                                                    />
                                                    <label id="input__label-limit" class="input__checkbox-label input__label-limit" for="input__limit"
                                                        >入金期限ルールに同意する。<span class="input__required">必須</span></label
                                                    >
                                                </div>
                                            </dd>
                                            <p class="input__error-text">入金期限ルールの同意をチェックしてください</p>
                                            <table class="input__table input__table-limit">
                                                <tr class="">
                                                    <th class=""></th>
                                                    <th class="">ご予約日が出発の8日以上前</th>
                                                    <th class="">ご予約日が出発の7日～2日前</th>
                                                </tr>
                                                <tr class="">
                                                    <th class="">入金期限</th>
                                                    <td class="">ご予約日を含めて3日目の23:59まで</td>
                                                    <td class="">ご予約日の翌日の23:59まで</td>
                                                </tr>
                                            </table>
                                            <p class="input__attention">入金期限内にご入金が無い場合は、ご予約を自動キャンセルさせていただきます。</p>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                    <!-- ご予約の重要事項 -->
                    <li id="form__item" class="form__item">
                        <div class="form__item-header form__item-header-confirm">
                            <h3 class="form__item-name form__item-name-confirm">ご予約の重要事項</h3>
                        </div>
                        <div class="form__item-container">
                            <div class="input">
                                <dl class="input__list">
                                    <!-- line -->
                                    <div class="input__line input__line-confirm">
                                        <p class="input__attention">下記事項を確認し、同意する場合はチェックを付けてください。</p>
                                        <!-- confirm -->
                                        <div id="input__item-confirm" class="input__item input__item-confirm">
                                            <dt id="input__header-confirm" class="input__header input__header-confirm">
                                                <label id="input__name-confirm" class="input__name input__name-confirm" for="">確認事項</label>
                                            </dt>
                                            <dd id="input__container-confirm" class="input__container input__container-confirm">
                                                <div id="input__note-confirm" class="input__note input__note-confirm">
                                                    <div class="note" v-show="canCredit">
                                                        <h4 class="note__name">クレジットカードでお支払いの場合</h4>
                                                        <p class="note__text">
                                                            当社では、GMO社による安全なクレジットカード決済システムを導入しております。
                                                        </p>
                                                        <h5 class="note__name-sub">《注意事項》</h5>
                                                        <p class="note__text">
                                                            ご利用可能なクレジットカードは、JCB、VISA、マスター、AMEXとなります。<br />
                                                            クレジットカードによるお支払いは、1回払いのみお取り扱いとなります。<br />
                                                            クレジットカード決済の完了をもって予約が完了いたします。<br />
                                                            予約変更はご予約申し込み時に決済したカードと同一カードでの決済となります。<br />
                                                            「ご予約された日程」と「キャンセルされた日程」により、返金処理の方法が異なり、大きく、下記2種類の処理パターンがございます。
                                                            （キャンセルにより返金が発生する際、各画面にてご確認いただけます。）
                                                        </p>
                                                        <ol class="note__list-ol">
                                                            <li class="note__item-ol">
                                                                一旦料金を全額お支払いいただき、キャンセル後の料金との差額を、クレジットカードご利用の口座にご返金となる場合
                                                            </li>
                                                            <li class="note__item-ol">
                                                                予約、変更に伴う料金が相殺されキャンセル後の料金のみをお支払いいただく場合
                                                            </li>
                                                        </ol>
                                                        <ul class="note__list">
                                                            <li class="note__item">
                                                                クレジットカードの締切り日によっては、ご返金までに1～2ヶ月ほどお時間がかかる場合がございますのでご了承ください。
                                                                （VISA・Master デビットカードは、お申込み時点で即時決済される場合がございます。）
                                                            </li>
                                                            <li class="note__item">
                                                                デビッドカードでのご利用代金のキャンセル等に伴う返金等の詳細については、各カード発行会社へお問合せください。
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="note" v-show="canCvsPayeasy">
                                                        <h4 class="note__name">Pay-easy、コンビニエンスストアでお支払いの場合</h4>
                                                        <p class="note__text">
                                                            各種お支払い方法につきましては
                                                            <a target="_blank" href="https://www.miyazakicarferry.com/booking/web/#cvs">こちら</a>
                                                            からご確認ください。
                                                        </p>
                                                        <h5 class="note__name-sub">《注意事項》</h5>
                                                        <p class="note__text">
                                                            期日までにご入金がない場合はご予約が自動キャンセルとなりますのでご注意ください。<br />
                                                            当社でのご入金確認が完了するまでは、お客様による変更手続をお受けできません。<br />
                                                            入金情報につきましてはマイページにてご確認ください。<br />
                                                            予約のキャンセル、変更に伴い返金が発生する場合、発生の都度<b>払戻手数料</b>をいただきます。詳しくは
                                                            <a target="_blank" href="https://www.miyazakicarferry.com/booking/web/#refund">こちら</a><br />
                                                            インターネット上でのご返金(クレジットカード払い以外の方)には、GMO社「GMO-PG送金サービス」を利用し、<br />
                                                            お客様ご指定の銀行口座へ返金いたします。予約変更取消後、送金手続きに必要なURLをメールでお送りします。<br />
                                                            銀行口座への払い戻しの際、差し引き額が0円以下となる場合はお振り込みいたしませんのでご了承ください。
                                                            <br />
                                                            なお、送金手続き完了後１週間程度のお時間を頂戴する場合がございます。<br />&nbsp;<br />&nbsp;
                                                        </p>
                                                    </div>
                                                    <div class="note" v-show="canOnTheDay">
                                                        <h4 class="note__name">当日各港でのお支払いの場合</h4>
                                                        <p class="note__text">
                                                            当日窓口で現金、クレジットカード、スマートフォン決済のいずれかでお支払いください。
                                                        </p>
                                                        <h5 class="note__name-sub">《注意事項》</h5>
                                                        <p class="note__text">
                                                            クレジットカードによるお支払いは1回もしくは2回払いのみのお取り扱いになります。<br />
                                                            ご利用できるクレジットカードの種類はJCB・VISA・MASTER・NICOS・UC・AMEXです。<br />
                                                            ご利用できるスマートフォン決済はPayPay、LINE Payに限ります。<br />
                                                            乗船当日の窓口にて不具合（クレジットカード磁気読み取り不能、バーコード決済エラー等）の場合は、<br />
                                                            現金にてお支払いいただく場合もございますのでご了承ください。
                                                        </p>
                                                    </div>
                                                </div>
                                            </dd>
                                            <dd class="input__container input__container-agree">
                                                <div id="input__checkbox-agree" class="input__checkbox input__checkbox-agree input__checkbox-agree">
                                                    <input
                                                        id="input__agree"
                                                        class="input__checkbox-btn input__agree"
                                                        type="checkbox"
                                                        name="input__agree"
                                                        v-model="agreement_booking"
                                                    />
                                                    <label id="input__label-agree" class="input__checkbox-label input__label-agree" for="input__agree"
                                                        >同意します。<span class="input__required">必須</span></label
                                                    >
                                                </div>
                                                <p class="input__error-text">確認事項の同意をチェックしてください</p>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-container" style="padding-top: 2rem">
                <button class="btn btn-back" type="button" v-on:click="backEditBooking">入力画面に戻る</button>
                <button class="btn btn-next" type="button" id="btnConfirmationBooking" v-on:click="confirmationBooking">予約を確定する</button>
            </div>
        </section>
        <!-- /input -->
    </main>
</template>
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, nextTick } from 'vue'
import $ from 'jquery'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
import { Booking, Contract, SearchConfirmationVacancy } from '@/lib/types'
import {
    AUTHORI_PAYMENT_METHOD_CREDIT,
    AUTHORI_PAYMENT_METHOD_PAY_EASY,
    AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
    CONVENIENCE_TYPE_SEVEN_ELEVEN,
    CONVENIENCE_TYPE_LAWSON,
    CONVENIENCE_TYPE_FAMILY_MART,
    CONVENIENCE_TYPE_MINI_STOP,
    SEAT_TYPE_D,
    COUNTER_PAYMENT_RESTRICT_ADVANCE,
    COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT,
    COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY,
    COUNTER_PAYMENT_RESTRICT_COUNTER,
    COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER,
    COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT_COUNTER,
    COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY_COUNTER
} from '@/lib/constants'
import { getBookingData, setBookingData, setSearchConfirmationVacancyData } from '@/lib/localStorageUtil'
import {
    API_USERS,
    API_ORDER,
    getId,
    joinFirstNameAndLastName,
    isNull,
    getShipSchedule,
    getContract,
    getStockCabin,
    getStockSeat,
    getStockCar,
    getStockBicycle,
    getStockPets,
    DEFAULT_TWO_WHEEL_LENGTH,
    DEFAULT_TWO_WHEEL_WIDTH,
    setCompanionMaster,
    setVehicleMaster,
} from '@/lib/util'
import { isCardName, isNumeric } from '@/lib/validation_util'

const PAYMENT_CARD = 'payment-card'
const PAYMENT_PAYEASY = 'payment-payeasy'
const PAYMENT_CONVENI = 'payment-conveni'
const PAYMENT_COUNTER = 'payment-counter'

const CARD_DATA = [
    {
        card_type: 'VISA',
        no_digit_1: 4,
        no_digit_2: 4,
        no_digit_3: 4,
        no_digit_4: 4,
        security_digit: 3,
    },
    {
        card_type: 'MASTER',
        no_digit_1: 4,
        no_digit_2: 4,
        no_digit_3: 4,
        no_digit_4: 4,
        security_digit: 3,
    },
    {
        card_type: 'JCB',
        no_digit_1: 4,
        no_digit_2: 4,
        no_digit_3: 4,
        no_digit_4: 4,
        security_digit: 3,
    },
    {
        card_type: 'DINERS',
        no_digit_1: 4,
        no_digit_2: 6,
        no_digit_3: 5,
        no_digit_4: 0,
        security_digit: 3,
    },
    {
        card_type: 'AMEX',
        no_digit_1: 4,
        no_digit_2: 6,
        no_digit_3: 4,
        no_digit_4: 0,
        security_digit: 4,
    },
]

export default defineComponent({
    setup(props, context) {
        const store = useStore()
        const router = useRouter()
        const authori_payment_method = ref()
        const card_no = ref('')
        // const card_no_1 = ref('');
        // const card_no_2 = ref('');
        // const card_no_3 = ref('');
        // const card_no_4 = ref('');
        const year = ref('')
        const month = ref('')
        const security_no = ref('')
        const card_name = ref('')
        const agreement_rule_pay_easy = ref(false)
        const convenience_type = ref('')
        const agreement_rule_convenience = ref(false)
        const agreement_booking = ref(false)
        const ship_schedule_id = ref()
        const contract = ref<Contract>()
        const car_line_no_matting_table = ref<any[]>([])

        // 予約情報登録（クレジット）
        const postBookingCredit = async () => {
            // トークン作成
            $(function () {
                ;(window as any).Multipayment.init(process.env.VUE_APP_PAYMENT_SHOP_ID)
                ;(window as any).Multipayment.getToken(
                    {
                        // cardno: card_no_1.value + card_no_2.value + card_no_3.value + card_no_4.value,
                        cardno: card_no.value,
                        expire: year.value + month.value,
                        securitycode: security_no.value,
                        holdername: card_name.value,
                        tokennumber: '1',
                    },
                    'window.creditCallbackFunction',
                )
            })
        }
        const creditCallbackFunction = async (response: any) => {
            if (response.resultCode != '000') {
                window.alert('購入処理中にエラーが発生しました')
            } else {
                let ret_url = ''
                let booking = getBookingData() as Booking
                let rel_boarding = makeBoardingData()
                let rel_passenger = makePassengerData()
                let rel_boarding_car = makeBoardingCarData()
                let rel_pet_cage = makePetCageData()

                const postData = {
                    contract_number: booking.contract_number,
                    booking_total_amout: booking.amount,
                    ship_schedule_id: ship_schedule_id.value,
                    rel_boarding: rel_boarding,
                    rel_passenger: rel_passenger,
                    rel_boarding_car: rel_boarding_car,
                    rel_pet_cage: rel_pet_cage,
                    sales_total: booking.amount,
                    add_send_emails: [],
                    card_token: response.tokenObject.token[0],
                }

                ret_url = await apiClient
                    .post(`${API_ORDER}/registration/credit`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                        // 認証URL取得
                        return data.redirectUrl
                    })
                    .catch((e: any) => {
                        // エラー処理
                        store.dispatch('setIsProcessing', false)
                        console.log(e.message)

                        let message: string[] = []
                        if (e.response && e.response?.status == 402) {
                            message = e.response!.data.toString().split(/\r\n|\n|\r/)
                        } else {
                            message = ['クレジットカードの認証に失敗しました。', 'ご予約をやり直してください。']
                        }

                        context.emit('displayMessage', {
                            msg_title: 'クレジットカード認証エラー',
                            msg: message,
                            close_btn: true,
                            ok_btn: false,
                            submit_btn: false,
                            close_event: backEditBooking,
                        })
                    })

                if (!isNull(ret_url)) {
                    // 処理完了判定
                    store.dispatch('setIsProcessing', false)
                    // リダイレクト
                    location.href = ret_url
                }
            }
        }

        // 予約情報登録（Pay-Easy）
        const postBookingPayEasy = async () => {
            let ret = false
            let booking = getBookingData() as Booking
            let rel_boarding = makeBoardingData()
            let rel_passenger = makePassengerData()
            let rel_boarding_car = makeBoardingCarData()
            let rel_pet_cage = makePetCageData()

            const postData = {
                contract_number: booking.contract_number,
                booking_total_amout: booking.amount,
                ship_schedule_id: ship_schedule_id.value,
                rel_boarding: rel_boarding,
                rel_passenger: rel_passenger,
                rel_boarding_car: rel_boarding_car,
                rel_pet_cage: rel_pet_cage,
                sales_total: booking.amount,
                add_send_emails: [],
            }
            await apiClient
                .post(`${API_ORDER}/registration/pay-easy`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    booking.boarding.boarding_no = data.boarding_no
                    setBookingData(booking)
                    ret = true
                })
                .catch((e: any) => {
                    // エラー処理
                    store.dispatch('setIsProcessing', false)
                    console.log(e.message)

                    let message: string[] = []
                    if (e.response && e.response?.status == 402) {
                        message = e.response!.data.toString().split(/\r\n|\n|\r/)
                    } else {
                        message = ['乗船予約に失敗しました。', 'ご予約をやり直してください。']
                    }

                    context.emit('displayMessage', {
                        msg_title: '乗船予約エラー',
                        msg: message,
                        close_btn: true,
                        ok_btn: false,
                        submit_btn: false,
                        ok_event: backEditBooking,
                    })
                })

            return ret
        }

        // 予約情報登録（コンビニ払い）
        const postBookingConvenience = async (): Promise<boolean> => {
            let ret = false
            let booking = getBookingData() as Booking
            let rel_boarding = makeBoardingData()
            let rel_passenger = makePassengerData()
            let rel_boarding_car = makeBoardingCarData()
            let rel_pet_cage = makePetCageData()

            const postData = {
                contract_number: booking.contract_number,
                booking_total_amout: booking.amount,
                ship_schedule_id: ship_schedule_id.value,
                rel_boarding: rel_boarding,
                rel_passenger: rel_passenger,
                rel_boarding_car: rel_boarding_car,
                rel_pet_cage: rel_pet_cage,
                sales_total: booking.amount,
                convenience_store: Number(convenience_type.value),
                add_send_emails: [],
            }
            await apiClient
                .post(`${API_ORDER}/registration/convenience`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    booking.boarding.boarding_no = data.boarding_no
                    setBookingData(booking)
                    ret = true
                })
                .catch((e: any) => {
                    // エラー処理
                    store.dispatch('setIsProcessing', false)
                    console.log(e.message)

                    let message: string[] = []
                    if (e.response && e.response?.status == 402) {
                        message = e.response!.data.toString().split(/\r\n|\n|\r/)
                    } else {
                        message = ['乗船予約に失敗しました。', 'ご予約をやり直してください。']
                    }

                    context.emit('displayMessage', {
                        msg_title: '乗船予約エラー',
                        msg: message,
                        close_btn: true,
                        ok_btn: false,
                        submit_btn: false,
                        ok_event: backEditBooking,
                    })
                })

            return ret
        }

        // 予約情報登録（当日決済）
        const postBookingCounter = async (): Promise<boolean> => {
            let ret = false
            let booking = getBookingData() as Booking
            let rel_boarding = makeBoardingData()
            let rel_passenger = makePassengerData()
            let rel_boarding_car = makeBoardingCarData()
            let rel_pet_cage = makePetCageData()

            const postData = {
                contract_number: booking.contract_number,
                booking_total_amout: booking.amount,
                ship_schedule_id: ship_schedule_id.value,
                rel_boarding: rel_boarding,
                rel_passenger: rel_passenger,
                rel_boarding_car: rel_boarding_car,
                rel_pet_cage: rel_pet_cage,
                sales_total: booking.amount,
                add_send_emails: [],
            }
            await apiClient
                .post(`${API_ORDER}/registration/counter`, postData)
                .then((res: AxiosResponse<any>) => {
                    const { status, data } = res
                    booking.boarding.boarding_no = data.boarding_no
                    setBookingData(booking)
                    // 認証URL取得
                    ret = true
                })
                .catch((e: any) => {
                    // エラー処理
                    store.dispatch('setIsProcessing', false)
                    console.log(e.message)
                    context.emit('displayMessage', {
                        msg_title: '乗船予約エラー',
                        msg: ['乗船予約に失敗しました。', 'ご予約をやり直してください。'],
                        close_btn: true,
                        ok_btn: false,
                        submit_btn: false,
                        close_event: backEditBooking,
                    })
                })

            return ret
        }

        // 乗船情報登録データ作成
        const makeBoardingData = () => {
            let booking = getBookingData() as Booking
            let boarding = booking.boarding
            let rel_boarding = {
                personality_type: boarding.personality_type,
                corporate_name: boarding.corporate_name,
                represent_passenger_name: boarding.represent_passenger_name,
                represent_passenger_tel: boarding.represent_passenger_tel,
                represent_passenger_prefectures: boarding.represent_passenger_prefectures,
                represent_passenger_post_code: boarding.represent_passenger_post_code,
                represent_passenger_address: boarding.represent_passenger_address,
                wheelchair: boarding.wheelchair,
                wheelchair_lending: boarding.wheelchair_lending,
                pregnant: boarding.pregnant,
                pregnant_months: isNull(boarding.pregnant_months) ? 0 : Number(boarding.pregnant_months),
                pet_num: boarding.dog_count + boarding.cat_count + boarding.other_count,
                oxygen_cylinder: boarding.oxygen_cylinder ? Number(boarding.oxygen_cylinder_count) : 0,
                dog_count: boarding.dog_count,
                cat_count: boarding.cat_count,
                other_count: boarding.other_count,
                pet_note: boarding.pet_note,
            }

            return rel_boarding
        }

        // 乗船者情報登録データ作成
        const makePassengerData = () => {
            let booking = getBookingData() as Booking
            let rel_passenger = [] as any[]
            let cabin = []
            let max_line_no = 0
            // 乗船者
            for (let i = 0; i < booking.headcount_distributions.length; i++) {
                cabin.push([] as any)
                let reserved_flg = false
                for (let j = 0; j < booking.headcount_distributions[i].passenger_detail_array.length; j++) {
                    let passenger = booking.headcount_distributions[i].passenger_detail_array[j]
                    if (max_line_no < passenger.line_no!) {
                        max_line_no = passenger.line_no!
                    }
                    cabin[i].push({
                        line_no: Number(passenger.line_no),
                        name_kana: joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana),
                        birthday: new Date(passenger.birthday_year!, passenger.birthday_month! - 1, passenger.birthday_day!),
                        gender: Number(passenger.gender),
                        age: Number(passenger.age),
                        passenger_type: Number(passenger.passenger_type),
                        grade: Number(booking.grade),
                        seat_type: Number(booking.seat_type),
                        represent_flg: passenger.represent_flg,
                        with_pets_flg: passenger.with_pets_flg,
                        driver: passenger.driver,
                        reserved_room: passenger.reserved_room ? true : null,
                        // car_row_no: passenger.car_row_no ? Number(passenger.car_row_no): null,
                        car_row_no: passenger.car_row_no ? getNewCarLineNo(passenger.car_row_no) : null,
                        amount: Number(passenger.unit_price),
                        baf: Number(passenger.baf_amount),
                        reserved_amount: Number(passenger.reserved_amount),
                        with_pets_amount: Number(passenger.with_pets_amount),
                    })
                    if (passenger.reserved_room) {
                        reserved_flg = true
                    }
                }
                if (reserved_flg == false) {
                    cabin[i][0].reserved_room = false
                }
            }
            // 乳幼児
            if (!isNull(booking.passenger_detail_baby_array)) {
                for (let i = 0; i < booking.passenger_detail_baby_array.length; i++) {
                    let passenger = booking.passenger_detail_baby_array[i]
                    passenger.line_no = max_line_no + 1
                    max_line_no = passenger.line_no!
                    cabin[0].push({
                        line_no: Number(passenger.line_no),
                        name_kana: joinFirstNameAndLastName(passenger.family_name_katakana, passenger.given_name_katakana),
                        birthday: new Date(passenger.birthday_year!, passenger.birthday_month! - 1, passenger.birthday_day!),
                        gender: Number(passenger.gender),
                        age: Number(passenger.age),
                        passenger_type: Number(passenger.passenger_type),
                        grade: Number(booking.grade),
                        seat_type: Number(booking.seat_type),
                        represent_flg: passenger.represent_flg,
                        with_pets_flg: passenger.with_pets_flg,
                        driver: passenger.driver,
                        reserved_room: passenger.reserved_room ? true : null,
                        // car_row_no: passenger.car_row_no ? Number(passenger.car_row_no): null,
                        car_row_no: passenger.car_row_no ? getNewCarLineNo(passenger.car_row_no) : null,
                        amount: Number(passenger.unit_price),
                        baf: Number(passenger.baf_amount),
                        reserved_amount: Number(passenger.reserved_amount),
                        with_pets_amount: Number(passenger.with_pets_amount),
                    })
                }
            }

            rel_passenger = cabin
            return rel_passenger
        }

        // 車両情報登録データ作成
        const makeBoardingCarData = () => {
            let booking = getBookingData() as Booking
            let rel_boarding_car = [] as any[]
            let save_car_cnt = 0
            let delete_car_cnt = 0

            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                rel_boarding_car.push({
                    // line_no: car.line_no,
                    line_no: getNewCarLineNo(car.line_no),
                    car_type_code: car.car_type_code,
                    car_length: Number(car.car_length),
                    car_width: Number(car.car_width),
                    car_place_name: car.car_place_name,
                    car_class_number: car.car_class_number,
                    car_hiragana: car.car_hiragana,
                    car_number: car.car_number,
                    note: car.note,
                    amount: car.unit_price,
                    baf: car.baf_amount,
                })
                if (car.deleted) {
                    delete_car_cnt += 1
                } else {
                    save_car_cnt += 1
                }
            }
            for (let i = 0; i < booking.two_wheel_detail_array.length; i++) {
                let car = booking.two_wheel_detail_array[i]
                let new_car_line_no = 0
                if (car.deleted) {
                    new_car_line_no = 99 - delete_car_cnt
                    delete_car_cnt += 1
                } else {
                    save_car_cnt += 1
                    new_car_line_no = save_car_cnt
                }
                rel_boarding_car.push({
                    // line_no: car_cnt + i,
                    // line_no: getNewCarLineNo(car.line_no),
                    line_no: new_car_line_no,
                    car_type_code: car.car_type_code,
                    car_length: DEFAULT_TWO_WHEEL_LENGTH,
                    car_width: DEFAULT_TWO_WHEEL_WIDTH,
                    car_place_name: car.car_place_name,
                    car_class_number: car.car_class_number,
                    car_hiragana: car.car_hiragana,
                    car_number: car.car_number,
                    note: car.note,
                    amount: car.unit_price,
                    baf: car.baf_amount,
                })
            }

            return rel_boarding_car
        }
        // 車両行番号対応表作成
        const makeCarLineNoMattingTable = () => {
            let booking = getBookingData() as Booking
            let save_car_line_no_idx = 1
            let delete_car_line_no_idx = 99
            car_line_no_matting_table.value = [] as any[]
            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                if (car.deleted) {
                    car_line_no_matting_table.value.push({
                        origin_car_line_no: car.line_no,
                        new_car_line_no: delete_car_line_no_idx,
                    } as any)
                    delete_car_line_no_idx -= 1
                } else {
                    car_line_no_matting_table.value.push({
                        origin_car_line_no: car.line_no,
                        new_car_line_no: save_car_line_no_idx,
                    } as any)
                    save_car_line_no_idx += 1
                }
            }
            // for (let i = 0;i < booking.two_wheel_detail_array.length; i++) {
            //   let car = booking.two_wheel_detail_array[i];
            //   if (car.deleted) {
            //     car_line_no_matting_table.value.push({
            //       origin_car_line_no: car.line_no,
            //       new_car_line_no: delete_car_line_no_idx
            //     } as any);
            //     delete_car_line_no_idx += 1;
            //   } else {
            //     car_line_no_matting_table.value.push({
            //       origin_car_line_no: car.line_no,
            //       new_car_line_no: save_car_line_no_idx
            //     } as any);
            //     save_car_line_no_idx += 1;
            //   }
            // }
        }
        // 車両行番号取得
        const getNewCarLineNo = (origin_car_line_no: number) => {
            let new_car_line_no = null
            for (let i = 0; i < car_line_no_matting_table.value.length; i++) {
                if (car_line_no_matting_table.value[i].origin_car_line_no == origin_car_line_no) {
                    new_car_line_no = car_line_no_matting_table.value[i].new_car_line_no
                    break
                }
            }
            return new_car_line_no
        }

        // ペットケージ登録データ作成
        const makePetCageData = () => {
            let booking = getBookingData() as Booking
            let rel_pet_cage = [] as any[]

            for (let i = 0; i < booking.cage_detail_array.length; i++) {
                let cage = booking.cage_detail_array[i]
                rel_pet_cage.push({
                    cage_type: cage.cage_type,
                })
            }

            return rel_pet_cage
        }

        // 検索条件の設定
        const setSearchInfo = async () => {
            const booking = getBookingData() as Booking

            if (booking != null) {
                setSearchConfirmationVacancyData({
                    dep_date: new Date(booking.dep_date),
                    sea_route_code: booking.sea_route_code,
                    passenger_adult_num: booking.passenger_adult_num,
                    passenger_child_num: booking.passenger_child_num,
                    passenger_infant_num: booking.passenger_infant_num,
                    passenger_baby_num: booking.passenger_baby_num,
                    car_3m_num: booking.car_3m_num,
                    car_4m_num: booking.car_4m_num,
                    car_5m_num: booking.car_5m_num,
                    car_6m_num: booking.car_6m_num,
                    car_bicycle_num: booking.car_bicycle_num,
                    car_two_wheel_small_num: booking.car_two_wheel_small_num,
                    car_two_wheel_medium_num: booking.car_two_wheel_medium_num,
                    car_two_wheel_large_num: booking.car_two_wheel_large_num,
                    cage_small_num: booking.cage_small_num,
                    cage_large_num: booking.cage_large_num,
                } as SearchConfirmationVacancy)
            }
        }

        // 予約入力へ戻る
        const backEditBooking = () => {
            router.push('/booking/registration')
        }

        // 予約する
        const confirmationBooking = async () => {
            // 処理中判定
            if (store.state.is_processing) {
                return
            }
            store.dispatch('setIsProcessing', true)

            let err_count = 0
            var landing = $('#form__list').offset()!.top

            // 支払方法
            $('#input__item-payment').removeClass('input__error')
            if (isNull(authori_payment_method.value)) {
                $('#input__item-payment').addClass('input__error')
                store.dispatch('setIsProcessing', false)
                // Scroll
                $('html,body').animate({ scrollTop: landing })
                setTimeout(() => {
                    context.emit('displayMessage', {
                        msg_title: '乗船予約エラー',
                        msg: ['お支払い情報が選択されていません。'],
                        close_btn: true,
                    })
                }, 800)
                return
            }
            // 在庫チェック
            if (!(await chkStock())) {
                store.dispatch('setIsProcessing', false)
                context.emit('displayMessage', {
                    msg_title: '乗船予約エラー',
                    msg: ['お部屋が満室となっております。'],
                    close_btn: true,
                })
                return
            }

            await setSearchInfo()

            if (authori_payment_method.value == PAYMENT_CARD) {
                // クレジットカード
                let error = chkValidationCredit()
                if (error.length > 0) {
                    store.dispatch('setIsProcessing', false)
                    $('html,body').animate({ scrollTop: landing })
                    setTimeout(() => {
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: error,
                            close_btn: true,
                        })
                    }, 800)
                    return
                }
                // 同行者情報更新
                err_count += await updCompanion()
                if (err_count > 0) {
                    return
                }
                // 車両情報更新
                err_count += await updVehicle()
                if (err_count > 0) {
                    return
                }

                // 決済処理実行
                await postBookingCredit()
                return
            } else if (authori_payment_method.value == PAYMENT_PAYEASY) {
                // Pay-Easy
                let error = chkValidationPayEasy()
                if (error.length > 0) {
                    store.dispatch('setIsProcessing', false)
                    $('html,body').animate({ scrollTop: landing })
                    setTimeout(() => {
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: error,
                            close_btn: true,
                        })
                    }, 800)
                    return
                }
                // 同行者情報更新
                err_count += await updCompanion()
                // 車両情報更新
                if (err_count > 0) {
                    return
                }
                err_count += await updVehicle()
                if (err_count > 0) {
                    return
                }
                let ret = await postBookingPayEasy()
                if (ret) {
                    // 処理完了判定
                    store.dispatch('setIsProcessing', false)
                    nextTick(() => {
                        router.push('/booking/complete')
                    })
                }
            } else if (authori_payment_method.value == PAYMENT_CONVENI) {
                // コンビニ払い
                let error = chkValidationConvenience()
                if (error.length > 0) {
                    store.dispatch('setIsProcessing', false)
                    $('html,body').animate({ scrollTop: landing })
                    setTimeout(() => {
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: error,
                            close_btn: true,
                        })
                    }, 800)
                    return
                }
                // 同行者情報更新
                err_count += await updCompanion()
                // 車両情報更新
                if (err_count > 0) {
                    return
                }
                err_count += await updVehicle()
                if (err_count > 0) {
                    return
                }
                let ret = await postBookingConvenience()
                if (ret) {
                    // 処理完了判定
                    store.dispatch('setIsProcessing', false)
                    nextTick(() => {
                        router.push('/booking/complete')
                    })
                }
            } else if (authori_payment_method.value == PAYMENT_COUNTER) {
                // 当日決済
                let error = chkValidationCounter()
                if (error.length > 0) {
                    store.dispatch('setIsProcessing', false)
                    context.emit('displayMessage', {
                        msg_title: '乗船予約エラー',
                        msg: error,
                        close_btn: true,
                    })
                    return
                }
                // 同行者情報更新
                err_count += await updCompanion()
                // 車両情報更新
                if (err_count > 0) {
                    return
                }
                err_count += await updVehicle()
                if (err_count > 0) {
                    return
                }
                let ret = await postBookingCounter()
                if (ret) {
                    // 処理完了判定
                    store.dispatch('setIsProcessing', false)
                    nextTick(() => {
                        router.push('/booking/complete')
                    })
                }
            } else {
                // 確認事項
                $('#input__item-confirm').removeClass('input__error')
                if (agreement_booking.value != true) {
                    $('#input__item-confirm').addClass('input__error')
                    err_count++
                }
            }

            // 処理完了判定
            store.dispatch('setIsProcessing', false)
        }

        // 入力チェック（クレジットカード）
        const chkValidationCredit = (): string[] => {
            console.log('↓↓↓SettlementEntry.chkValidationCredit↓↓↓')
            let err_messages = []
            // カード番号・セキュリティコード
            $('#input__item-cardno').removeClass('input__error')
            $('#input__item-security').removeClass('input__error')
            // if (isNull(card_no_1.value) || isNull(card_no_2.value) || isNull(card_no_3.value)
            //  || !isNumeric(card_no_1.value) || !isNumeric(card_no_2.value) || !isNumeric(card_no_3.value)) {
            //   $('#input__item-cardno').addClass('input__error');
            //   err_messages.push('カード番号を半角数字で入力してください。');
            //   if (isNull(security_no.value) || !isNumeric(security_no.value)) {
            //     $('#input__item-security').addClass('input__error');
            //     err_messages.push('セキュリティコードを半角数字で入力してください。');
            //   }
            // } else if (card_no_1.value.length == 4 && card_no_2.value.length == 6 && card_no_3.value.length == 5) {
            //   // アメックス
            //   if (!isNull(card_no_4.value) || !isNumeric(card_no_4.value)) {
            //     $('#input__item-cardno').addClass('input__error');
            //     err_messages.push('カード番号が正しくありません。');
            //   }
            //   if (isNull(security_no.value) || !isNumeric(security_no.value) || security_no.value.length != 4) {
            //     $('#input__item-security').addClass('input__error');
            //     if (isNull(security_no.value) || !isNumeric(security_no.value)) {
            //       err_messages.push('セキュリティコードを半角数字で入力してください。')
            //     } else {
            //       err_messages.push('セキュリティコードが正しくありません。');
            //     }
            //   }
            // } else if (card_no_1.value.length == 4 && card_no_2.value.length == 6 && card_no_3.value.length == 4) {
            //   // ダイナース
            //   if (!isNull(card_no_4.value) || !isNumeric(card_no_4.value)) {
            //     $('#input__item-cardno').addClass('input__error');
            //     err_messages.push('カード番号が正しくありません。');
            //   }
            //   if (isNull(security_no.value) || !isNumeric(security_no.value) || security_no.value.length != 3) {
            //     $('#input__item-security').addClass('input__error');
            //     if (isNull(security_no.value) || !isNumeric(security_no.value)) {
            //       err_messages.push('セキュリティコードを半角数字で入力してください。')
            //     } else {
            //       err_messages.push('セキュリティコードが正しくありません。');
            //     }
            //   }
            // } else if (card_no_1.value.length == 4 && card_no_2.value.length == 4 && card_no_3.value.length == 4) {
            //   // VISA,マスター,JCB
            //   if (isNull(card_no_4.value) || !isNumeric(card_no_4.value) || card_no_4.value.length != 4) {
            //     $('#input__item-cardno').addClass('input__error');
            //     err_messages.push('カード番号が正しくありません。');
            //   }
            //   if (isNull(security_no.value) || !isNumeric(security_no.value) || security_no.value.length != 3) {
            //     $('#input__item-security').addClass('input__error');
            //     if (isNull(security_no.value) || !isNumeric(security_no.value)) {
            //       err_messages.push('セキュリティコードを半角数字で入力してください。')
            //     } else {
            //       err_messages.push('セキュリティコードが正しくありません。');
            //     }
            //   }
            // } else {
            //   $('#input__item-cardno').addClass('input__error');
            //   err_messages.push('カード番号が正しくありません。');
            //   if (isNull(security_no.value) || !isNumeric(security_no.value)) {
            //     $('#input__item-security').addClass('input__error');
            //     err_messages.push('セキュリティコードを半角数字で入力してください。');
            //   }
            // }

            if (isNull(card_no.value) || !isNumeric(card_no.value)) {
                $('#input__item-cardno').addClass('input__error')
                err_messages.push('カード番号を半角数字で入力してください。')
                if (isNull(security_no.value) || !isNumeric(security_no.value)) {
                    $('#input__item-security').addClass('input__error')
                    err_messages.push('セキュリティコードを半角数字で入力してください。')
                }
            } else if (card_no.value.length !== 14 && card_no.value.length !== 15 && card_no.value.length !== 16) {
                $('#input__item-cardno').addClass('input__error')
                err_messages.push('カード番号が正しくありません。')
                if (isNull(security_no.value) || !isNumeric(security_no.value)) {
                    $('#input__item-security').addClass('input__error')
                    err_messages.push('セキュリティコードを半角数字で入力してください。')
                }
            } else {
                if (isNull(security_no.value) || !isNumeric(security_no.value)) {
                    err_messages.push('セキュリティコードを半角数字で入力してください。')
                } else if (security_no.value.length != 3 && security_no.value.length != 4) {
                    err_messages.push('セキュリティコードが正しくありません。')
                } else {
                    if (
                        !(
                            (card_no.value.length == 14 && security_no.value.length === 3) ||
                            (card_no.value.length == 15 && security_no.value.length === 4) ||
                            (card_no.value.length == 16 && security_no.value.length === 3)
                        )
                    ) {
                        $('#input__item-security').addClass('input__error')
                        err_messages.push('セキュリティコードが正しくありません。')
                    }
                }
            }
            // 有効期限
            $('#input__container-expiry').removeClass('input__error')
            $('#input__item-expiry').removeClass('input__error')
            if (isNull(year.value) || isNull(month.value)) {
                $('#input__container-expiry').addClass('input__error')
                $('#input__item-expiry').addClass('input__error')
                err_messages.push('有効期限を入力してください。')
            }
            // カード名義人
            $('#input__item-cardname').removeClass('input__error')
            if (isNull(card_name.value)) {
                $('#input__item-cardname').addClass('input__error')
                err_messages.push('カード名義人を入力してください。')
            } else if (!isCardName(card_name.value)) {
                $('#input__item-cardname').addClass('input__error')
                err_messages.push(
                    'カード名義人は、「半角英字」によるご入力および、姓と名の間に「半角スペース」をご入力くださいますようお願いします。',
                )
            }
            // 確認事項
            $('#input__item-confirm').removeClass('input__error')
            if (agreement_booking.value != true) {
                $('#input__item-confirm').addClass('input__error')
                err_messages.push('確認事項の同意チェックをしてください。')
            }
            console.log('↑↑↑SettlementEntry.chkValidationCredit↑↑↑')
            return err_messages
        }
        // 入力チェック（Pay-Easy）
        const chkValidationPayEasy = (): string[] => {
            console.log('↓↓↓SettlementEntry.chkValidationPayEasy↓↓↓')
            let err_messages = []
            // 入金期限ルール
            $('#input__item-limit').removeClass('input__error')
            if (agreement_rule_convenience.value != true) {
                $('#input__item-limit').addClass('input__error')
                err_messages.push('入金期限ルールの同意をチェックしてください。')
            }
            // 確認事項
            $('#input__item-confirm').removeClass('input__error')
            if (agreement_booking.value != true) {
                $('#input__item-confirm').addClass('input__error')
                err_messages.push('確認事項の同意チェックをしてください。')
            }
            console.log('↑↑↑SettlementEntry.chkValidationPayEasy↑↑↑')
            return err_messages
        }
        // 入力チェック（コンビニ払い）
        const chkValidationConvenience = (): string[] => {
            console.log('↓↓↓SettlementEntry.chkValidationConvenience↓↓↓')
            let err_messages = []
            // コンビニ選択
            $('#input__item-conveni').removeClass('input__error')
            if (isNull(convenience_type.value)) {
                $('#input__item-conveni').addClass('input__error')
                err_messages.push('決済するコンビニを選択してください。')
            }
            // 入金期限ルール
            $('#input__item-limit').removeClass('input__error')
            if (agreement_rule_convenience.value != true) {
                $('#input__item-limit').addClass('input__error')
                err_messages.push('入金期限ルールの同意をチェックしてください。')
            }
            // 確認事項
            $('#input__item-confirm').removeClass('input__error')
            if (agreement_booking.value != true) {
                $('#input__item-confirm').addClass('input__error')
                err_messages.push('確認事項の同意チェックをしてください。')
            }
            console.log('↑↑↑SettlementEntry.chkValidationConvenience↑↑↑')
            return err_messages
        }
        // 入力チェック（当日決済）
        const chkValidationCounter = (): string[] => {
            console.log('↓↓↓SettlementEntry.chkValidationConvenience↓↓↓')
            let err_messages = []
            // 確認事項
            $('#input__item-confirm').removeClass('input__error')
            if (agreement_booking.value != true) {
                $('#input__item-confirm').addClass('input__error')
                err_messages.push('確認事項の同意チェックをしてください。')
            }
            console.log('↑↑↑SettlementEntry.chkValidationConvenience↑↑↑')
            return err_messages
        }
        // 入力チェック（在庫チェック）
        const chkStock = async (): Promise<boolean> => {
            console.log('↓↓↓SettlementEntry.chkStock↓↓↓')
            let err_count = 0
            let booking = getBookingData() as Booking

            if (booking.seat_type < SEAT_TYPE_D) {
                // 船室
                let stock = await getStockCabin(
                    contract.value!.divide_type_id,
                    booking.seat_type,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    booking.headcount_distributions.length,
                )
                ship_schedule_id.value = stock.ship_schedule_id
                if (stock.empty_status == 0) {
                    err_count++
                }
            } else {
                // 船席
                let people_num = 0
                for (let i = 0; i < booking.headcount_distributions.length; i++) {
                    people_num += booking.headcount_distributions[i].adult
                    people_num += booking.headcount_distributions[i].child
                }
                let stock = await getStockSeat(
                    contract.value!.divide_type_id,
                    booking.seat_type,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    people_num,
                )
                ship_schedule_id.value = stock.ship_schedule_id
                if (stock.empty_status == 0) {
                    err_count++
                }
            }
            // 乗用車
            let car_num = 0
            car_num += booking.car_3m_num
            car_num += booking.car_4m_num
            car_num += booking.car_5m_num
            car_num += booking.car_6m_num
            if (car_num > 0) {
                let stock_car = await getStockCar(contract.value!.divide_type_id, booking.sea_route_code, booking.dep_date, booking.dep_date, car_num)
                if (stock_car.empty_status == 0) {
                    err_count++
                }
            }
            // 二輪車
            let bike_num = 0
            bike_num += booking.car_bicycle_num
            bike_num += booking.car_two_wheel_small_num
            bike_num += booking.car_two_wheel_medium_num
            bike_num += booking.car_two_wheel_large_num
            if (bike_num > 0) {
                let stock_bike = await getStockBicycle(
                    contract.value!.divide_type_id,
                    booking.sea_route_code,
                    booking.dep_date,
                    booking.dep_date,
                    bike_num,
                )
                if (stock_bike.empty_status == 0) {
                    err_count++
                }
            }
            // ペットルーム
            if (booking.cage_large_num > 0 || booking.cage_small_num > 0) {
                let stock_pets = await getStockPets(booking.sea_route_code, booking.dep_date, booking.cage_large_num, booking.cage_small_num)
                if (booking.cage_large_num > 0 && stock_pets.large_status == 0) {
                    err_count++
                }
                if (booking.cage_small_num && stock_pets.middle_status == 0) {
                    err_count++
                }
            }

            console.log('↑↑↑SettlementEntry.chkStock↑↑↑')
            return err_count == 0
        }

        // 同乗者情報更新
        const updCompanion = async (): Promise<number> => {
            let err_count = 0
            let companions = []

            let booking = getBookingData() as Booking
            // 同乗者
            for (let i = 0; i < booking.headcount_distributions.length; i++) {
                for (let j = 0; j < booking.headcount_distributions[i].passenger_detail_array.length; j++) {
                    let passenger = booking.headcount_distributions[i].passenger_detail_array[j]
                    if (passenger.register_companion) {
                        companions.push({
                            id: isNull(passenger.companion_id) ? null : Number(passenger.companion_id), // 同行者ID
                            passenger_type: Number(passenger.passenger_type), // 乗船者区分
                            family_name_katakana: passenger.family_name_katakana, // 氏名（セイ）
                            given_name_katakana: passenger.given_name_katakana, // 氏名（メイ）
                            sex: Number(passenger.gender), // 性別
                            member_id: passenger.mcf_member_id, // 会員ID
                            deleted: false, // 削除フラグ
                        })
                    }
                }
            }
            // 乳幼児
            for (let i = 0; i < booking.passenger_detail_baby_array.length; i++) {
                let passenger = booking.passenger_detail_baby_array[i]
                if (passenger.register_companion) {
                    companions.push({
                        id: isNull(passenger.companion_id) ? null : Number(passenger.companion_id), // 同行者ID
                        passenger_type: Number(passenger.passenger_type), // 乗船者区分
                        family_name_katakana: passenger.family_name_katakana, // 氏名（セイ）
                        given_name_katakana: passenger.given_name_katakana, // 氏名（メイ）
                        sex: Number(passenger.gender), // 性別
                        member_id: passenger.mcf_member_id, // 会員ID
                        deleted: false, // 削除フラグ
                    })
                }
            }

            if (companions.length > 0) {
                const postData = {
                    companion: companions,
                }
                await apiClient
                    .post(`${API_USERS}/companion`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        store.dispatch('setIsProcessing', false)
                        err_count++
                        console.log(e.message)
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: ['乗船予約に失敗しました。', 'ご予約をやり直してください。'],
                            close_btn: true,
                        })
                    })
                setCompanionMaster(store.state.member_id)
            }

            return err_count
        }

        // 車両情報更新
        const updVehicle = async (): Promise<number> => {
            let err_count = 0
            let vehicles = []

            let booking = getBookingData() as Booking
            for (let i = 0; i < booking.car_detail_array.length; i++) {
                let car = booking.car_detail_array[i]
                if (car.register_car) {
                    vehicles.push({
                        id: isNull(car.vehicle_id) ? null : Number(car.vehicle_id), // 車両ID
                        car_name: car.note, // 車名
                        car_type_code: car.car_type_code, // 車両種別コード
                        number: car.car_number, // No.
                        place_name: car.car_place_name, // 地域
                        class_number: car.car_class_number, // 分類
                        hiragana: car.car_hiragana, // 符号
                        length: Number(car.car_length), // 車長
                        deleted: false, // 削除フラグ
                    })
                }
            }
            for (let i = 0; i < booking.two_wheel_detail_array.length; i++) {
                let car = booking.two_wheel_detail_array[i]
                if (car.register_car) {
                    vehicles.push({
                        id: isNull(car.vehicle_id) ? null : Number(car.vehicle_id), // 車両ID
                        car_name: car.note, // 車名
                        car_type_code: car.car_type_code, // 車両種別コード
                        number: car.car_number, // No.
                        place_name: car.car_place_name, // 地域
                        class_number: car.car_class_number, // 分類
                        hiragana: car.car_hiragana, // 符号
                        length: isNull(car.car_length) ? null : Number(car.car_length), // 車長
                        deleted: false, // 削除フラグ
                    })
                }
            }

            if (vehicles.length > 0) {
                const postData = {
                    vehicle: vehicles,
                }
                await apiClient
                    .post(`${API_USERS}/vehicle`, postData)
                    .then((res: AxiosResponse<any>) => {
                        const { status, data } = res
                    })
                    .catch((e: AxiosError<{ error: string }>) => {
                        // エラー処理
                        store.dispatch('setIsProcessing', false)
                        err_count++
                        console.log(e.message)
                        context.emit('displayMessage', {
                            msg_title: '乗船予約エラー',
                            msg: ['乗船予約に失敗しました。', 'ご予約をやり直してください。'],
                            close_btn: true,
                        })
                    })
                setVehicleMaster(store.state.member_id)
            }

            return err_count
        }

        // 成約情報取得
        const getContractData = async () => {
            let booking = getBookingData() as Booking
            if (booking == null) {
                router.push('/booking/confirmation_vacancy')
                return
            }
            contract.value = await getContract(booking.contract_number, booking.dep_date, booking.sea_route_code)
            makeCarLineNoMattingTable()
        }

        // カード番号入力後処理
        const blurCardNo = () => {
            card_no.value = card_no.value.replace(/[- \s]/g, '')
        }

        /** コンビニ決済可能か */
        const canCredit = computed(() => {
            if (isNull(contract.value)) {
                return false
            }
            const credit = [
                COUNTER_PAYMENT_RESTRICT_ADVANCE,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT_COUNTER
            ]
            return credit.includes(contract.value!.counter_payment_restrict)
        })
        /** コンビニ、Pay-easy決済可能か */
        const canCvsPayeasy = computed(() => {
            if (isNull(contract.value)) {
                return false
            }
            const csv_payeasy = [
                COUNTER_PAYMENT_RESTRICT_ADVANCE,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY_COUNTER
            ]
            return csv_payeasy.includes(contract.value!.counter_payment_restrict)
        })
        /** 当日決済可否 */
        const canOnTheDay = computed(() => {
            if (isNull(contract.value)) {
                return false
            }
            const counter = [
                COUNTER_PAYMENT_RESTRICT_COUNTER,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT_COUNTER,
                COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY_COUNTER
            ]
            return counter.includes(contract.value!.counter_payment_restrict)
        })

        // 有効期限：年
        const expiryYears = computed(() => {
            let years = [] as any
            years.push({ text: '--', value: '' })
            let now = new Date()
            const baseYear = now.getFullYear()
            for (let i = 0; i < 20; i++) {
                years.push({
                    text: String(baseYear + i),
                    value: String(baseYear + i).slice(2, 4),
                })
            }

            return years
        })

        // watch(card_name, (next, prev) => {
        //   const pattern = /[^A-Za-z0-9 ,.\-/]/g;
        //   card_name.value = card_name.value.replace(pattern, "");
        // });

        onMounted(async () => {
            console.log('↓↓↓SettlementEntry.onMounted↓↓↓')
            // クレジットカード認証用
            ;(window as any).creditCallbackFunction = creditCallbackFunction

            await getContractData()
            console.log('↑↑↑SettlementEntry.onMounted↑↑↑')
        })

        return {
            authori_payment_method,
            card_no,
            // card_no_1,
            // card_no_2,
            // card_no_3,
            // card_no_4,
            year,
            month,
            security_no,
            card_name,
            agreement_rule_pay_easy,
            convenience_type,
            agreement_rule_convenience,
            agreement_booking,
            ship_schedule_id,

            isNull,

            backEditBooking,
            confirmationBooking,
            getContractData,
            blurCardNo,

            canCredit,
            canCvsPayeasy,
            canOnTheDay,
            expiryYears,
        }
    },
    emits: ['hideBar', 'showBar', 'showPrintReceipt', 'displayMessage'],
    name: 'SettlementEntry',
    data() {
        return {
            AUTHORI_PAYMENT_METHOD_CREDIT: AUTHORI_PAYMENT_METHOD_CREDIT as number,
            AUTHORI_PAYMENT_METHOD_PAY_EASY: AUTHORI_PAYMENT_METHOD_PAY_EASY as number,
            AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE: AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE as number,

            PAYMENT_CARD: PAYMENT_CARD as string,
            PAYMENT_PAYEASY: PAYMENT_PAYEASY as string,
            PAYMENT_CONVENI: PAYMENT_CONVENI as string,
            PAYMENT_COUNTER: PAYMENT_COUNTER as string,

            CONVENIENCE_TYPE_SEVEN_ELEVEN: CONVENIENCE_TYPE_SEVEN_ELEVEN as string,
            CONVENIENCE_TYPE_LAWSON: CONVENIENCE_TYPE_LAWSON as string,
            CONVENIENCE_TYPE_FAMILY_MART: CONVENIENCE_TYPE_FAMILY_MART as string,
            CONVENIENCE_TYPE_MINI_STOP: CONVENIENCE_TYPE_MINI_STOP as string,

            // Multipayment_init: window.Multipayment.init('tshop00058856'),
        }
    },
    components: {},
    props: {},
    beforeUnmount: function () {
        const script = document.getElementById('script_id_payment_link')
        if (script) {
            script.parentNode!.removeChild(script)
        }
    },
    methods: {},
    mounted: function () {
        console.log('↓↓↓settlementEntry.mounted↓↓↓')
        scrollTo(0, 0)
        //payment select
        let radio_btns = document.querySelectorAll(`input[type='radio'][name='input__payment']`)

        for (let target of radio_btns) {
            target.addEventListener(`change`, function () {
                //
            })
        }

        //payment select
        $(function () {
            $('input[name="input__payment"]:radio').change(function () {
                var radioval = $(this).val()
                if (radioval == 'payment-card') {
                    $('#form__item-payment').removeClass('select-none')
                    $('#form__item-payment').removeClass('select-payeasy')
                    $('#form__item-payment').removeClass('select-conveni')
                    $('#form__item-payment').addClass('select-card')
                }
                if (radioval == 'payment-payeasy') {
                    $('#form__item-payment').removeClass('select-none')
                    $('#form__item-payment').removeClass('select-card')
                    $('#form__item-payment').removeClass('select-conveni')
                    $('#form__item-payment').addClass('select-payeasy')
                }
                if (radioval == 'payment-conveni') {
                    $('#form__item-payment').removeClass('select-none')
                    $('#form__item-payment').removeClass('select-card')
                    $('#form__item-payment').removeClass('select-payeasy')
                    $('#form__item-payment').addClass('select-conveni')
                }
            })
        })

        console.log('↑↑↑settlementEntry.mounted↑↑↑')
    },
    created: function () {
        const script = document.createElement('script')
        script.setAttribute('src', process.env.VUE_APP_PAYMENT_LINK_URL)
        script.setAttribute('id', 'script_id_payment_link')
        document.head.appendChild(script)
    },
})
</script>

<style></style>
