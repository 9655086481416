import { isNull } from './util'

// 運航状態
export const DEP_STATUS_NORMAL = 0
export const DEP_STATUS_CANCEL_WEATHER = 1
export const DEP_STATUS_CANCEL_ETC = 2
export const DEP_STATUS_DOCK = 3

// 予約種別
export const BOOKING_TYPE_CUSTOMER = 0
export const BOOKING_TYPE_CARGO = 1

// 予約状態
export const BOOKING_STATUS_PRE_SELL = 0
export const BOOKING_STATUS_RESERVED = 1
export const BOOKING_STATUS_TICKETED = 2
export const BOOKING_STATUS_DISCARD = 3

// 削除理由
export const DELETE_REASON_NONE = 0
export const DELETE_REASON_BY_DEL_BOARDING = 1
export const DELETE_REASON_BY_DEL_DETAILS = 90
export const DELETE_REASON_BY_DEL_LOAD_RECORD = 91
export const DELETE_REASON_BY_DEL_MA = 92
export const DELETE_REASON_BY_PATCH = 99

// 取扱区分
export const HANDLING_DIVISION_GENERAL = 0
export const HANDLING_DIVISION_WEB = 1
export const HANDLING_DIVISION_PERSONAL = 2
export const HANDLING_DIVISION_GROUP_PLAN = 3
export const HANDLING_DIVISION_GROUP_GENERAL = 4

// 額種
export const PRICE_TYPE_GROSS = 0
export const PRICE_TYPE_NET = 1

// 精算方法
export const PAYMENT_TYPE_PREVIOUS = 0
export const PAYMENT_TYPE_TODAY = 1
export const PAYMENT_TYPE_AFTER = 2

// 性別
export const GENDER_MALE = 0
export const GENDER_FEMALE = 1

// 乗船者区分
export const PASSENGER_TYPE_ADULT = 0
export const PASSENGER_TYPE_CHILD = 1
export const PASSENGER_TYPE_INFANT = 2
export const PASSENGER_TYPE_BABY = 3

// 船室種別
export const CABIN_TYPE_NONE = 0
export const CABIN_TYPE_MALE = 1
export const CABIN_TYPE_FEMALE = 2
export const CABIN_TYPE_GROUP = 3
export const CABIN_TYPE_ASSIGN_BY_SHIP = 4
export const CABIN_TYPE_ASSIGN_BY_PORT = 5
export const CABIN_TYPE_MALE_AND_FEMALE = 6

// 食事区分(利用内容)
export const MEAL_TYPE_BREAKFAST = 0
export const MEAL_TYPE_DINNER = 1
export const MEAL_TYPE_OTHER = 99

// 車両予約状態
export const CAR_STATUS_RESERVED = 0
export const CAR_STATUS_ADJUSTING = 1
export const CAR_STATUS_WAIT_CANCEL = 2

// 明細種別
export const DETAIL_TYPE_PASSENGER_FARE = 0
export const DETAIL_TYPE_CAR_FARE = 1
export const DETAIL_TYPE_OTHER = 9

// 券種
export const TICKET_TYPE_BOAT_TICKET = 0
export const TICKET_TYPE_CARGO_TICKET = 1
export const TICKET_TYPE_CASH = 2
export const TICKET_TYPE_CREDIT = 3
export const TICKET_TYPE_E_MONEY = 4
export const TICKET_TYPE_WEB = 5
export const TICKET_TYPE_BANK = 6

// 決済種別
export const PAY_METHOD_BOAT_TICKET = 0
export const PAY_METHOD_BOAT_TICKET_AFTER = 1
export const PAY_METHOD_BOAT_TICKET_FUJOU = 2
export const PAY_METHOD_CARGO_TICKET = 3
export const PAY_METHOD_CASH = 4
export const PAY_METHOD_CREDIT = 5
export const PAY_METHOD_E_MONEY = 6
export const PAY_METHOD_WEB = 7
export const PAY_METHOD_BANK = 8

// 追加決済事由
export const ADD_PAY_REASON_CHANGE_GRADE_FRONT = 0
export const ADD_PAY_REASON_CHANGE_CAR_INFO = 1
export const ADD_PAY_REASON_CHANGE_GRADE_SHIP = 2
export const ADD_PAY_REASON_TICKETING_SHIP = 3
export const ADD_PAY_REASON_CHANGE_PASSENGER_NUM = 4
export const ADD_PAY_REASON_CHANGE_CAR_NUM = 5
export const ADD_PAY_REASON_DISCOUNT_CHANGE = 6
export const ADD_PAY_REASON_LOAD_AGENT_CHARGE = 7
export const ADD_PAY_REASON_OTHER = 99

// キャンセル料率
export const CANCEL_RATE_0 = 0
export const CANCEL_RATE_200 = 1
export const CANCEL_RATE_10_PERCENT = 2
export const CANCEL_RATE_30_PERCENT = 3
export const CANCEL_RATE_100_PERCENT = 4

// 金種
export const DENOMINATION_1 = 1
export const DENOMINATION_5 = 5
export const DENOMINATION_10 = 10
export const DENOMINATION_50 = 50
export const DENOMINATION_100 = 100
export const DENOMINATION_500 = 500
export const DENOMINATION_1000 = 1000
export const DENOMINATION_5000 = 5000
export const DENOMINATION_10000 = 10000

// 釣り銭
export const CHANGE_PATTERN_0 = 0
export const CHANGE_PATTERN_50000 = 50000
export const CHANGE_PATTERN_75000 = 75000

// 上り下り
export const UP = 0
export const DOWN = 1

// 港
export const PORT_MIYAZAKI = 0
export const PORT_KOBE = 1

// 座席位置
export const SEAT_POSITION_NONE = 0
export const SEAT_POSITION_UP = 1
export const SEAT_POSITION_DOWN = 2

// 業者区分
export const SUPPLIER_TYPE_AGENT = 0
export const SUPPLIER_TYPE_CARRIER = 1

// 団体精算方法
export const GROUP_PAY_TYPE_COUPON = 0
export const GROUP_PAY_TYPE_CASH_NO_INVOICE = 1
export const GROUP_PAY_TYPE_CASH_INVOICE = 2

// 口座種別
export const ACCOUNT_TYPE_ORDINARY = 0
export const ACCOUNT_TYPE_CURRENT = 1

// 精算区分
export const SUPPLIER_PAY_TYPE_DEPARTURE = 0
export const SUPPLIER_PAY_TYPE_ARRIVAL = 1

// 税処理方式
export const TAX_METHOD_NONE = 0
export const TAX_METHOD_INCLUDE = 1
export const TAX_METHOD_EXCLUDE = 2

// 税区分
export const TAX_CATEGORY_TAXATION = 0

// 扱区分（勘定科目マスタ）
export const USE_CATEGORY_NONE = 0

// 貸借区分
export const DEBIT_CREDIT_NONE = 0
export const DEBIT = 1
export const CREDIT = 2

// 適用方法
export const APPLY_METHOD_RATE = 0
export const APPLY_METHOD_AMOUNT = 1

// 割引適用対象
export const DISCOUNT_TARGET_INDIVIDUAL = 0
export const DISCOUNT_TARGET_GROUP = 1
export const DISCOUNT_TARGET_MEAL = 2

// 等級
export const GRADE_SP = 0
export const GRADE_01 = 1
export const GRADE_02_BED = 2
export const GRADE_02 = 3
export const GRADE_DR = 4
export const GRADE_P = 10
export const GRADE_F = 11
export const GRADE_S = 12
export const GRADE_DS = 13
export const GRADE_D = 14
export const GRADE_T = 15

// BAF種別
export const BAF_TYPE_PASSENGER = 0
export const BAF_TYPE_CAR = 1

// 積降区分
export const LOAD_DIVISION_LOAD = 0
export const LOAD_DIVISION_UNLOAD = 1

// 算出順序
export const CALC_ORDER_EXCLUDE = 0
export const CALC_ORDER_INCLUDE = 1

// 請求書発行方法
export const INVOICE_METHOD_INDIVIDUAL = 0
export const INVOICE_METHOD_ALL = 1

// SQL管理ステータス
export const MNG_SQL_STAT_NOT_EXECUTED = 0
export const MNG_SQL_STAT_EXECUTED = 1
export const MNG_SQL_STAT_COMPLETE = 2
export const MNG_SQL_STAT_ERROR_EXIT = 3

// データ出力条件パターン
export const DATA_OUTPUT_PUBLIC = 0
export const DATA_OUTPUT_PRIVATE = 1

// 所在地県
export const PREFECTURE_HOKKAIDO = '01'
export const PREFECTURE_AOMORI = '02'
export const PREFECTURE_IWATE = '03'
export const PREFECTURE_MIYAGI = '04'
export const PREFECTURE_AKITA = '05'
export const PREFECTURE_YAMAGATA = '06'
export const PREFECTURE_FUKUSHIMA = '07'
export const PREFECTURE_IBARAKI = '08'
export const PREFECTURE_TOCHIGI = '09'
export const PREFECTURE_GUNMA = '10'
export const PREFECTURE_SAITAMA = '11'
export const PREFECTURE_CHIBA = '12'
export const PREFECTURE_TOKYO = '13'
export const PREFECTURE_KANAGAWA = '14'
export const PREFECTURE_NIGATA = '15'
export const PREFECTURE_TOYAMA = '16'
export const PREFECTURE_ISHIKAWA = '17'
export const PREFECTURE_FUKUI = '18'
export const PREFECTURE_YAMANASHI = '19'
export const PREFECTURE_NAGANO = '20'
export const PREFECTURE_GIFU = '21'
export const PREFECTURE_SHIZUOKA = '22'
export const PREFECTURE_AICHI = '23'
export const PREFECTURE_MIE = '24'
export const PREFECTURE_SHIGA = '25'
export const PREFECTURE_KYOTO = '26'
export const PREFECTURE_OSAKA = '27'
export const PREFECTURE_HYOGO = '28'
export const PREFECTURE_NARA = '29'
export const PREFECTURE_WAKAYAMA = '30'
export const PREFECTURE_TOTTORI = '31'
export const PREFECTURE_SHIMANE = '32'
export const PREFECTURE_OKAYAMA = '33'
export const PREFECTURE_HIROSHIMA = '34'
export const PREFECTURE_YAMAGUCHI = '35'
export const PREFECTURE_TOKUSHIMA = '36'
export const PREFECTURE_KAGAWA = '37'
export const PREFECTURE_EHIME = '38'
export const PREFECTURE_KOCHI = '39'
export const PREFECTURE_FUKUOKA = '40'
export const PREFECTURE_SAGA = '41'
export const PREFECTURE_NAGASAKI = '42'
export const PREFECTURE_KUMAMOTO = '43'
export const PREFECTURE_OITA = '44'
export const PREFECTURE_MIYAZAKI = '45'
export const PREFECTURE_KAGOSHIMA = '46'
export const PREFECTURE_OKINAWA = '47'
export const PREFECTURE_OVERSEAS = '50'

// PCA伝票区分
export const PCA_SLIP_TYPES_SALES = 0
export const PCA_SLIP_TYPES_DEPOSIT_CARGO = 1

// 船席割付種別
export const SEAT_ASSIGNMENT_TYPE_NORMAL = 0
export const SEAT_ASSIGNMENT_TYPE_FREE = 1
export const SEAT_ASSIGNMENT_TYPE_GROUP = 2

// 船席割付操作
export const SEAT_ASSIGNMENT_OP_COMMITTED = 0
export const SEAT_ASSIGNMENT_OP_CREATE = 1
export const SEAT_ASSIGNMENT_OP_UPDATE = 2
export const SEAT_ASSIGNMENT_OP_DELETE = 3

// 貸切種別
export const RESERVED_ROOM_TYPE_FREE = 0
export const RESERVED_ROOM_TYPE_CHARGED = 1

// システム権限
export const SYSTEM_AUTHORITY_OWNER = 0
export const SYSTEM_AUTHORITY_ADMIN = 1
export const SYSTEM_AUTHORITY_GENERAL = 2
export const SYSTEM_AUTHORITY_KFC = 3

// 請求書出力種別
export const INVOICE_OUTPUT_TYPE_COMMON = 0
export const INVOICE_OUTPUT_TYPE_SUPPLIER_ONLY = 1

// システム区分
export const HISTORY_CHG_TYPE_M_ONE_BOOKING = 0
export const HISTORY_CHG_TYPE_M_ONE_TICKET = 1
export const HISTORY_CHG_TYPE_MA = 2
export const HISTORY_CHG_TYPE_WEB = 3

// 所属
export const AFFILIATION_MIYAZAKI = 0
export const AFFILIATION_KOBE = 1

// 席数区分
export const SEAT_TYPE_SP = 0
export const SEAT_TYPE_01_S = 1
export const SEAT_TYPE_01_A = 2
export const SEAT_TYPE_01_B = 3
export const SEAT_TYPE_02_BED = 4
export const SEAT_TYPE_02 = 5
export const SEAT_TYPE_DR = 6
export const SEAT_TYPE_CAR = 7
export const SEAT_TYPE_TWO_AXLE_CAR = 8
export const SEAT_TYPE_P_TW = 10
export const SEAT_TYPE_P_TR = 11
export const SEAT_TYPE_P_BA = 12
export const SEAT_TYPE_F_TW = 13
export const SEAT_TYPE_F_FO = 14
export const SEAT_TYPE_FWP_TW = 15
export const SEAT_TYPE_FWP_FO = 16
export const SEAT_TYPE_S = 17
export const SEAT_TYPE_DS = 18
export const SEAT_TYPE_D = 19
export const SEAT_TYPE_T = 20
export const SEAT_TYPE_FERRY_CAR = 21
export const SEAT_TYPE_FERRY_TWO_AXLE_CAR = 22

// 計算端数処理
export const ROUNDING_RATE_NONE = 0
export const ROUNDING_RATE_ROUND_DOWN = 1
export const ROUNDING_RATE_ROUND_OFF = 2
export const ROUNDING_RATE_ROUND_UP = 3

// 商品分類
export const PLAN_ITEM_TYPE_BASE = 0
export const PLAN_ITEM_TYPE_BOARDING = 1
export const PLAN_ITEM_TYPE_HOTEL = 2
export const PLAN_ITEM_TYPE_ETC = 3

// 席数確保種別
export const SEAT_SECURE_TYPE_SECURE = 0
export const SEAT_SECURE_TYPE_LIMIT = 1

// 入金状態
export const AGENT_PAYMENT_STATUS_NOT_PAYMENT = 0
export const AGENT_PAYMENT_STATUS_PART_PAYMENT = 1
export const AGENT_PAYMENT_STATUS_PAYMENT = 2
export const AGENT_PAYMENT_STATUS_OVER_PAYMENT = 3
export const AGENT_PAYMENT_STATUS_LATER_DAY = 4
export const AGENT_PAYMENT_STATUS_NO_INVOICE = 5

// 代理店決済区分
export const AGENT_SETTLEMENT_TYPE_PAYMENT = 0
export const AGENT_SETTLEMENT_TYPE_REPAYMENT = 1

// 代理店入金方法
export const AGENT_PAYMENT_TYPE_LATER_DAY = 0
export const AGENT_PAYMENT_TYPE_TRANSFER = 1
export const AGENT_PAYMENT_TYPE_CASH = 2
export const AGENT_PAYMENT_TYPE_AGENT_PAYMENT = 3

// 代理店払戻方法
export const AGENT_REPAYMENT_TYPE_TRANSFER = 0
export const AGENT_REPAYMENT_TYPE_CASH = 1

// 往復
export const ROUND_TRIP_OUTWARD = 0
export const ROUND_TRIP_HOMEWARD = 1

// 代理店予約状態
export const AGENT_BOOKING_STATUS_BOOKING = 0
export const AGENT_BOOKING_STATUS_CANCEL = 99

// プラン部店
export const PLAN_DEPARTMENT_MIYAZAKI = 0
export const PLAN_DEPARTMENT_KOBE = 1

// プラン性別制限
export const PLAN_GENDER_RESTRICT_NONE = 0
export const PLAN_GENDER_RESTRICT_FEMALE = 1
export const PLAN_GENDER_RESTRICT_MALE = 2

// プラン片道制限
export const PLAN_ONE_WAY_RESTRICT_NOT_SET = 0
export const PLAN_ONE_WAY_RESTRICT_SET = 1

// 端数処理
export const ROUNDING_UNIT_1 = 1
export const ROUNDING_UNIT_10 = 10
export const ROUNDING_UNIT_100 = 100
export const ROUNDING_UNIT_1000 = 1000
export const ROUNDING_UNIT_10000 = 10000

// 食事精算方法
export const MEAL_PAYMENT_TYPE_PREVIOUS = 0
export const MEAL_PAYMENT_TYPE_TODAY = 1
export const MEAL_PAYMENT_TYPE_AFTER = 2
export const MEAL_PAYMENT_TYPE_COUPON = 3

// 基準航路
export const STANDARD_ROUTE_NO_ONE = 0
export const STANDARD_ROUTE_NO_TWO = 1

// 港
export const HARBOR_MIYAZAKI = 0
export const HARBOR_KOBE = 1
export const HARBOR_HOSOSHIMA = 2

// 世代区分
export const GENERATION_TYPE_EXPRESS = 0
export const GENERATION_TYPE_FERRY = 1

// バウチャ出力有無
export const VOUCHER_OUTPUT_NONE = 0
export const VOUCHER_OUTPUT_BULK = 1
export const VOUCHER_OUTPUT_INDIVIDUAL = 2

// ケージ種別
export const CAGE_TYPES_LARGE = 0
export const CAGE_TYPES_SMALL = 1
export const CAGE_TYPES_EXTRA = 2

// 掲示区分
export const POST_CATEGORY_NOTICES = 0
export const POST_CATEGORY_PRECAUTIONS = 1

// キャンセル計算方法
export const CANCEL_CALCULATION_METHODS_RETIO = 0
export const CANCEL_CALCULATION_METHODS_AMOUNT = 1

// 発売期間区分
export const SALES_PERIOD_TYPE_MONTH = 0
export const SALES_PERIOD_TYPE_DAYS = 1
export const SALES_PERIOD_TYPE_PERIOD = 2

// 性別制限
export const GENDER_RESTRICT_NONE = 0
export const GENDER_RESTRICT_MALE = 1
export const GENDER_RESTRICT_FEMALE = 2

// 車両制限
export const VEHICLE_RESTRICT_NONE = 0
export const VEHICLE_RESTRICT_CAR = 1
export const VEHICLE_RESTRICT_TWO_AXLE_CAR = 2
export const VEHICLE_RESTRICT_PASSENGER = 3

// お子様連れ制限
export const CHILD_RESTRICT_NONE = 0
export const CHILD_RESTRICT_CHILD = 1
export const CHILD_RESTRICT_CHILD_OR_INFANT = 2

// 決済方法制限
export const COUNTER_PAYMENT_RESTRICT_ADVANCE = 0
export const COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT = 1
export const COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY = 2
export const COUNTER_PAYMENT_RESTRICT_COUNTER = 3
export const COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER = 4
export const COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT_COUNTER = 5
export const COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY_COUNTER = 6

// 決済結果
export const AUTHORI_RESULT_UNPROCESSED = 0
export const AUTHORI_RESULT_AUTHENTICATED = 1
export const AUTHORI_RESULT_CHECK = 2
export const AUTHORI_RESULT_CAPTURE = 3
export const AUTHORI_RESULT_AUTH = 4
export const AUTHORI_RESULT_SALES = 5
export const AUTHORI_RESULT_VOID = 6
export const AUTHORI_RESULT_RETURN = 7
export const AUTHORI_RESULT_RETURNX = 8
export const AUTHORI_RESULT_SAUTH = 9

// オーソリ決済種別
export const AUTHORI_PAYMENT_METHOD_CREDIT = 0
export const AUTHORI_PAYMENT_METHOD_PAY_EASY = 1
export const AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE = 2

// お知らせ区分
export const INFORMATION_TYPE_COMMON = 0
export const INFORMATION_TYPE_SEA_ROUTE = 1

// 人格区分
export const PERSONALITY_TYPE_INDIVIDUAL = 0
export const PERSONALITY_TYPE_CORPORATION = 1

// コンビニ区分
export const CONVENIENCE_TYPE_SEVEN_ELEVEN = '00007'
export const CONVENIENCE_TYPE_LAWSON = '10001'
export const CONVENIENCE_TYPE_FAMILY_MART = '10002'
export const CONVENIENCE_TYPE_MINI_STOP = '10005'
export const CONVENIENCE_TYPE_SEICOMART = '10008'

// 取引内容
export const TRANSACTION_TYPE_BOOKING = 0
export const TRANSACTION_TYPE_CHANGE = 1
export const TRANSACTION_TYPE_CANCEL = 2

// メールアドレス状態
export const EMAIL_STATUS_UNVERIFIED = 0
export const EMAIL_STATUS_VERIFIED = 1

// 規約区分
export const TERMS_TYPE_TERMS = 0
export const TERMS_TYPE_PRIVACY_POLICY = 1

// 運航状態
export const DEP_STATUSES = [
    { text: '通常', value: DEP_STATUS_NORMAL },
    { text: '欠航（荒天）', value: DEP_STATUS_CANCEL_WEATHER },
    { text: '欠航（その他）', value: DEP_STATUS_CANCEL_ETC },
    { text: 'ドック', value: DEP_STATUS_DOCK },
]

// 予約種別
export const BOOKING_TYPES = [
    { text: '旅客', value: BOOKING_TYPE_CUSTOMER },
    { text: '貨物', value: BOOKING_TYPE_CARGO },
]

// 予約状態
export const BOOKING_STATUSES = [
    { text: '前売', value: BOOKING_STATUS_PRE_SELL },
    { text: '予約済', value: BOOKING_STATUS_RESERVED },
    { text: '発券済', value: BOOKING_STATUS_TICKETED },
    { text: '廃札', value: BOOKING_STATUS_DISCARD },
]

// 削除理由
export const DELETE_REASONS = [
    { text: 'なし', value: DELETE_REASON_NONE },
    { text: '乗船情報削除オペレーション', value: DELETE_REASON_BY_DEL_BOARDING },
    { text: '明細行削除オペレーション', value: DELETE_REASON_BY_DEL_DETAILS },
    {
        text: '積降実績削除オペレーション',
        value: DELETE_REASON_BY_DEL_LOAD_RECORD,
    },
    { text: '代理店(MA)削除オペレーション', value: DELETE_REASON_BY_DEL_MA },
    { text: 'データパッチ', value: DELETE_REASON_BY_PATCH },
]

// 取扱区分
export const HANDLING_DIVISIONS = [
    { text: '個人(一般)', value: HANDLING_DIVISION_GENERAL },
    { text: '個人(WEB)', value: HANDLING_DIVISION_WEB },
    { text: 'パーソナル', value: HANDLING_DIVISION_PERSONAL },
    { text: '企画団体', value: HANDLING_DIVISION_GROUP_PLAN },
    { text: '一般団体', value: HANDLING_DIVISION_GROUP_GENERAL },
]

// 額種
export const PRICE_TYPES = [
    { text: 'グロス', value: PRICE_TYPE_GROSS },
    { text: 'ネット', value: PRICE_TYPE_NET },
]

// 精算方法
export const PAYMENT_TYPES = [
    { text: '事前精算', value: PAYMENT_TYPE_PREVIOUS },
    { text: '当日精算', value: PAYMENT_TYPE_TODAY },
    { text: '後日精算', value: PAYMENT_TYPE_AFTER },
]

// 性別
export const GENDERS = [
    { text: '男', value: GENDER_MALE },
    { text: '女', value: GENDER_FEMALE },
]

// 乗船者区分
export const PASSENGER_TYPES = [
    { text: '大人', value: PASSENGER_TYPE_ADULT },
    { text: '小人', value: PASSENGER_TYPE_CHILD },
    { text: '幼児', value: PASSENGER_TYPE_INFANT },
    { text: '乳児', value: PASSENGER_TYPE_BABY },
]

// 船室種別
export const CABIN_TYPES = [
    { text: '制限なし', value: CABIN_TYPE_NONE },
    { text: '男性専用', value: CABIN_TYPE_MALE },
    { text: '女性専用', value: CABIN_TYPE_FEMALE },
    { text: '団体', value: CABIN_TYPE_GROUP },
    { text: '船持ち', value: CABIN_TYPE_ASSIGN_BY_SHIP },
    { text: '港持ち', value: CABIN_TYPE_ASSIGN_BY_PORT },
    { text: '男女', value: CABIN_TYPE_MALE_AND_FEMALE },
]

// 食事区分(利用内容)
export const MEAL_TYPES = [
    { text: '朝食', value: MEAL_TYPE_BREAKFAST },
    { text: '夕食', value: MEAL_TYPE_DINNER },
    { text: 'その他', value: MEAL_TYPE_OTHER },
]

// 車両予約状態
export const DETAIL_BOOKING_STATUSES = [
    { text: '予約確定', value: CAR_STATUS_RESERVED },
    { text: '調整中', value: CAR_STATUS_ADJUSTING },
    { text: 'キャンセル待ち', value: CAR_STATUS_WAIT_CANCEL },
]

// 明細種別
export const DETAIL_TYPES = [
    { text: '乗船者運賃', value: DETAIL_TYPE_PASSENGER_FARE },
    { text: '車両運賃', value: DETAIL_TYPE_CAR_FARE },
    { text: 'その他', value: DETAIL_TYPE_OTHER },
]

// 券種
export const TICKET_TYPES = [
    { text: '船車券', value: TICKET_TYPE_BOAT_TICKET },
    { text: '航送券', value: TICKET_TYPE_CARGO_TICKET },
    { text: '現金', value: TICKET_TYPE_CASH },
    { text: 'クレジット', value: TICKET_TYPE_CREDIT },
    { text: '電子マネー', value: TICKET_TYPE_E_MONEY },
    { text: 'WEB', value: TICKET_TYPE_WEB },
    { text: '銀行振込', value: TICKET_TYPE_BANK },
]

// 決済種別
export const PAYMENT_METHODS = [
    { text: '船車券', value: PAY_METHOD_BOAT_TICKET },
    { text: '船車券(後日)', value: PAY_METHOD_BOAT_TICKET_AFTER },
    { text: '船車券(不乗)', value: PAY_METHOD_BOAT_TICKET_FUJOU },
    { text: '航送券', value: PAY_METHOD_CARGO_TICKET },
    { text: '現金', value: PAY_METHOD_CASH },
    { text: 'クレジット', value: PAY_METHOD_CREDIT },
    { text: '電子マネー', value: PAY_METHOD_E_MONEY },
    { text: 'WEB', value: PAY_METHOD_WEB },
    { text: '銀行返金', value: PAY_METHOD_BANK },
]

// 追加決済事由
export const ADD_PAYMENT_REASONS = [
    { text: '窓口等変', value: ADD_PAY_REASON_CHANGE_GRADE_FRONT },
    { text: '車両情報変更', value: ADD_PAY_REASON_CHANGE_CAR_INFO },
    { text: '船内等変', value: ADD_PAY_REASON_CHANGE_GRADE_SHIP },
    { text: '船内発券', value: ADD_PAY_REASON_TICKETING_SHIP },
    { text: '人数増減', value: ADD_PAY_REASON_CHANGE_PASSENGER_NUM },
    { text: '車両増減', value: ADD_PAY_REASON_CHANGE_CAR_NUM },
    { text: '割引変更', value: ADD_PAY_REASON_DISCOUNT_CHANGE },
    { text: '積降代行料', value: ADD_PAY_REASON_LOAD_AGENT_CHARGE },
    { text: 'その他', value: ADD_PAY_REASON_OTHER },
]

// キャンセル料率
export const CANCEL_RATES = [
    { text: '－', value: CANCEL_RATE_0 },
    { text: '200円', value: CANCEL_RATE_200 },
    { text: '10%', value: CANCEL_RATE_10_PERCENT },
    { text: '30%', value: CANCEL_RATE_30_PERCENT },
    { text: '100%', value: CANCEL_RATE_100_PERCENT },
]

// 金種
export const DENOMINATIONS = [
    { text: '1円', value: DENOMINATION_1 },
    { text: '5円', value: DENOMINATION_5 },
    { text: '10円', value: DENOMINATION_10 },
    { text: '50円', value: DENOMINATION_50 },
    { text: '100円', value: DENOMINATION_100 },
    { text: '500円', value: DENOMINATION_500 },
    { text: '1,000円', value: DENOMINATION_1000 },
    { text: '5,000円', value: DENOMINATION_5000 },
    { text: '10,000円', value: DENOMINATION_10000 },
]

// 釣り銭
export const CHANGE_PATTERNS = [
    { text: '0円', value: CHANGE_PATTERN_0 },
    { text: '50,000円', value: CHANGE_PATTERN_50000 },
    { text: '75,000円', value: CHANGE_PATTERN_75000 },
]

// 上り下り
export const UP_DOWN = [
    { text: '上り', value: UP },
    { text: '下り', value: DOWN },
]

// 港
export const PORTS = [
    { text: '宮崎', value: PORT_MIYAZAKI },
    { text: '神戸', value: PORT_KOBE },
]

// 座席位置
export const SEAT_POSITIONS = [
    { text: '無し', value: SEAT_POSITION_NONE },
    { text: '上側', value: SEAT_POSITION_UP },
    { text: '下側', value: SEAT_POSITION_DOWN },
]

// 業者区分
export const SUPPLIER_TYPES = [
    { text: '代理店', value: SUPPLIER_TYPE_AGENT },
    { text: '運送業者', value: SUPPLIER_TYPE_CARRIER },
]

// 団体精算方法
export const GROUP_PAYMENT_TYPES = [
    { text: 'クーポン精算', value: GROUP_PAY_TYPE_COUPON },
    { text: '現金精算（請求なし）', value: GROUP_PAY_TYPE_CASH_NO_INVOICE },
    { text: '現金精算（請求あり）', value: GROUP_PAY_TYPE_CASH_INVOICE },
]

// 口座種別
export const ACCOUNT_TYPES = [
    { text: '普通', value: ACCOUNT_TYPE_ORDINARY },
    { text: '当座', value: ACCOUNT_TYPE_CURRENT },
]

// 精算区分
export const SUPPLIER_PAYMENT_TYPES = [
    { text: '発券精算（請求書発行なし）', value: SUPPLIER_PAY_TYPE_DEPARTURE },
    { text: '着券精算（請求書発行あり）', value: SUPPLIER_PAY_TYPE_ARRIVAL },
]

// 税処理方式
export const TAX_METHODS = [
    { text: '対象外', value: TAX_METHOD_NONE },
    { text: '税込', value: TAX_METHOD_INCLUDE },
    { text: '税抜', value: TAX_METHOD_EXCLUDE },
]

// 税区分
export const TAX_CATEGORIES = [{ text: '課税売上', value: TAX_CATEGORY_TAXATION }]

// 扱区分（勘定科目マスタ）
export const USE_CATEGORIES = [{ text: '無し', value: USE_CATEGORY_NONE }]

// 貸借区分
export const DEBIT_CREDIT = [
    { text: '無し', value: DEBIT_CREDIT_NONE },
    { text: '借方', value: DEBIT },
    { text: '貸方', value: CREDIT },
]

// 適用方法
export const APPLY_METHODS = [
    { text: '率採用', value: APPLY_METHOD_RATE },
    { text: '固定額採用', value: APPLY_METHOD_AMOUNT },
]

// 割引適用対象
export const DISCOUNT_TARGETS = [
    { text: '個人', value: DISCOUNT_TARGET_INDIVIDUAL },
    { text: '団体', value: DISCOUNT_TARGET_GROUP },
    { text: '食事', value: DISCOUNT_TARGET_MEAL },
]

// 等級
export const GRADES = [
    { text: '特等', value: GRADE_SP },
    { text: '1等', value: GRADE_01 },
    { text: '2等寝台', value: GRADE_02_BED },
    { text: '2等', value: GRADE_02 },
    { text: 'DR室', value: GRADE_DR },
    { text: 'プレミアム', value: GRADE_P },
    { text: 'ファースト', value: GRADE_F },
    { text: 'シングル', value: GRADE_S },
    { text: 'ドライバーシングル', value: GRADE_DS },
    { text: 'ドミトリー', value: GRADE_D },
    { text: 'ツーリスト', value: GRADE_T },
]

// BAF種別
export const BAF_TYPES = [
    { text: '乗船者', value: BAF_TYPE_PASSENGER },
    { text: '車両', value: BAF_TYPE_CAR },
]

// 積降区分
export const LOAD_DIVISIONS = [
    { text: '積み', value: LOAD_DIVISION_LOAD },
    { text: '降ろし', value: LOAD_DIVISION_UNLOAD },
]

// 算出順序
export const CALC_ORDERS = [
    { text: '抜割', value: CALC_ORDER_EXCLUDE },
    { text: '込割', value: CALC_ORDER_INCLUDE },
]

// 請求書発行方法
export const INVOICE_METHODS = [
    { text: '個別', value: INVOICE_METHOD_INDIVIDUAL },
    { text: 'まとめ', value: INVOICE_METHOD_ALL },
]

// SQL管理ステータス
export const MANAGE_SQL_STATUSES = [
    { text: '未実行', value: MNG_SQL_STAT_NOT_EXECUTED },
    { text: '実行中', value: MNG_SQL_STAT_EXECUTED },
    { text: '完了', value: MNG_SQL_STAT_COMPLETE },
    { text: 'エラー終了', value: MNG_SQL_STAT_ERROR_EXIT },
]

// データ出力条件パターン
export const DATA_OUTPUT_PUBLISH_STATUSES = [
    { text: '非公開', value: DATA_OUTPUT_PUBLIC },
    { text: '公開', value: DATA_OUTPUT_PRIVATE },
]

// 所在地県
export const PREFECTURES = [
    { text: '北海道', value: PREFECTURE_HOKKAIDO },
    { text: '青森県', value: PREFECTURE_AOMORI },
    { text: '岩手県', value: PREFECTURE_IWATE },
    { text: '宮城県', value: PREFECTURE_MIYAGI },
    { text: '秋田県', value: PREFECTURE_AKITA },
    { text: '山形県', value: PREFECTURE_YAMAGATA },
    { text: '福島県', value: PREFECTURE_FUKUSHIMA },
    { text: '茨城県', value: PREFECTURE_IBARAKI },
    { text: '栃木県', value: PREFECTURE_TOCHIGI },
    { text: '群馬県', value: PREFECTURE_GUNMA },
    { text: '埼玉県', value: PREFECTURE_SAITAMA },
    { text: '千葉県', value: PREFECTURE_CHIBA },
    { text: '東京都', value: PREFECTURE_TOKYO },
    { text: '神奈川県', value: PREFECTURE_KANAGAWA },
    { text: '新潟県', value: PREFECTURE_NIGATA },
    { text: '富山県', value: PREFECTURE_TOYAMA },
    { text: '石川県', value: PREFECTURE_ISHIKAWA },
    { text: '福井県', value: PREFECTURE_FUKUI },
    { text: '山梨県', value: PREFECTURE_YAMANASHI },
    { text: '長野県', value: PREFECTURE_NAGANO },
    { text: '岐阜県', value: PREFECTURE_GIFU },
    { text: '静岡県', value: PREFECTURE_SHIZUOKA },
    { text: '愛知県', value: PREFECTURE_AICHI },
    { text: '三重県', value: PREFECTURE_MIE },
    { text: '滋賀県', value: PREFECTURE_SHIGA },
    { text: '京都府', value: PREFECTURE_KYOTO },
    { text: '大阪府', value: PREFECTURE_OSAKA },
    { text: '兵庫県', value: PREFECTURE_HYOGO },
    { text: '奈良県', value: PREFECTURE_NARA },
    { text: '和歌山県', value: PREFECTURE_WAKAYAMA },
    { text: '鳥取県', value: PREFECTURE_TOTTORI },
    { text: '島根県', value: PREFECTURE_SHIMANE },
    { text: '岡山県', value: PREFECTURE_OKAYAMA },
    { text: '広島県', value: PREFECTURE_HIROSHIMA },
    { text: '山口県', value: PREFECTURE_YAMAGUCHI },
    { text: '徳島県', value: PREFECTURE_TOKUSHIMA },
    { text: '香川県', value: PREFECTURE_KAGAWA },
    { text: '愛媛県', value: PREFECTURE_EHIME },
    { text: '高知県', value: PREFECTURE_KOCHI },
    { text: '福岡県', value: PREFECTURE_FUKUOKA },
    { text: '佐賀県', value: PREFECTURE_SAGA },
    { text: '長崎県', value: PREFECTURE_NAGASAKI },
    { text: '熊本県', value: PREFECTURE_KUMAMOTO },
    { text: '大分県', value: PREFECTURE_OITA },
    { text: '宮崎県', value: PREFECTURE_MIYAZAKI },
    { text: '鹿児島県', value: PREFECTURE_KAGOSHIMA },
    { text: '沖縄県', value: PREFECTURE_OKINAWA },
    { text: '海外', value: PREFECTURE_OVERSEAS },
]

// PCA伝票区分
export const PCA_SLIP_TYPES = [
    { text: '売上伝票', value: PCA_SLIP_TYPES_SALES },
    { text: '航送券入金伝票', value: PCA_SLIP_TYPES_DEPOSIT_CARGO },
]

// 船席割付種別
export const SEAT_ASSIGNMENT_TYPES = [
    { text: '通常', value: SEAT_ASSIGNMENT_TYPE_NORMAL },
    { text: '自由', value: SEAT_ASSIGNMENT_TYPE_FREE },
    { text: '団体割付', value: SEAT_ASSIGNMENT_TYPE_GROUP },
]

// 船席割付操作
export const SEAT_ASSIGNMENT_OPERATIONS = [
    { text: '確定', value: SEAT_ASSIGNMENT_OP_COMMITTED },
    { text: '新規', value: SEAT_ASSIGNMENT_OP_CREATE },
    { text: '変更', value: SEAT_ASSIGNMENT_OP_UPDATE },
    { text: '削除', value: SEAT_ASSIGNMENT_OP_DELETE },
]

// 貸切種別
export const RESERVED_ROOM_TYPES = [
    { text: '無料', value: RESERVED_ROOM_TYPE_FREE },
    { text: '有料', value: RESERVED_ROOM_TYPE_CHARGED },
]

// システム権限
export const SYSTEM_AUTHORITY = [
    { text: 'オーナー', value: SYSTEM_AUTHORITY_OWNER },
    { text: '管理者', value: SYSTEM_AUTHORITY_ADMIN },
    { text: '一般', value: SYSTEM_AUTHORITY_GENERAL },
    { text: 'KFC', value: SYSTEM_AUTHORITY_KFC },
]

// 請求書出力種別
export const INVOICE_OUTPUT_TYPES = [
    { text: '共通フォーム', value: INVOICE_OUTPUT_TYPE_COMMON },
    { text: '業者専用フォーム', value: INVOICE_OUTPUT_TYPE_SUPPLIER_ONLY },
]

// システム区分
export const SYSTEM_TYPES = [
    { text: 'M-ONE(予約)', value: HISTORY_CHG_TYPE_M_ONE_BOOKING },
    { text: 'M-ONE(発券)', value: HISTORY_CHG_TYPE_M_ONE_TICKET },
    { text: '代理店(MA)', value: HISTORY_CHG_TYPE_MA },
    { text: 'WEB', value: HISTORY_CHG_TYPE_WEB },
]

// 所属
export const AFFILIATIONS = [
    { text: '宮崎', value: AFFILIATION_MIYAZAKI },
    { text: '神戸', value: AFFILIATION_KOBE },
]

// 席数区分
export const SEAT_TYPES = [
    { text: '特等', value: SEAT_TYPE_SP },
    { text: '1等S', value: SEAT_TYPE_01_S },
    { text: '1等A', value: SEAT_TYPE_01_A },
    { text: '1等B', value: SEAT_TYPE_01_B },
    { text: '2等寝台', value: SEAT_TYPE_02_BED },
    { text: '2等', value: SEAT_TYPE_02 },
    { text: 'DR室', value: SEAT_TYPE_DR },
    { text: '車両', value: SEAT_TYPE_CAR },
    { text: '２輪', value: SEAT_TYPE_TWO_AXLE_CAR },
    { text: 'プレミアムツイン', value: SEAT_TYPE_P_TW },
    { text: 'プレミアムトリプル', value: SEAT_TYPE_P_TR },
    { text: 'プレミアムバリアフリー', value: SEAT_TYPE_P_BA },
    { text: 'ファーストツイン', value: SEAT_TYPE_F_TW },
    { text: 'ファーストフォース', value: SEAT_TYPE_F_FO },
    { text: 'ファーストwithペットツイン', value: SEAT_TYPE_FWP_TW },
    { text: 'ファーストwithペットフォース', value: SEAT_TYPE_FWP_FO },
    { text: 'シングル', value: SEAT_TYPE_S },
    { text: 'シングル', value: SEAT_TYPE_DS }, // DRシングルはシングル表記
    { text: 'ドミトリー', value: SEAT_TYPE_D },
    { text: 'ツーリスト', value: SEAT_TYPE_T },
    { text: '車両', value: SEAT_TYPE_FERRY_CAR },
    { text: '２輪', value: SEAT_TYPE_FERRY_TWO_AXLE_CAR },
]

// 計算端数処理
export const ROUNDING_RATES = [
    { text: '手数料なし', value: ROUNDING_RATE_NONE },
    { text: '切捨て', value: ROUNDING_RATE_ROUND_DOWN },
    { text: '四捨五入', value: ROUNDING_RATE_ROUND_OFF },
    { text: '切上げ', value: ROUNDING_RATE_ROUND_UP },
]

// 商品分類
export const ITEM_TYPES = [
    { text: 'ベース', value: PLAN_ITEM_TYPE_BASE },
    { text: '乗船', value: PLAN_ITEM_TYPE_BOARDING },
    { text: 'ホテル', value: PLAN_ITEM_TYPE_HOTEL },
    { text: 'その他', value: PLAN_ITEM_TYPE_ETC },
]

// 席数確保種別
export const SEAT_SECURE_TYPES = [
    { text: '席数確保', value: SEAT_SECURE_TYPE_SECURE },
    { text: '限度数', value: SEAT_SECURE_TYPE_LIMIT },
]

// 入金状態
export const AGENT_PAYMENT_STATUSES = [
    { text: '未入金', value: AGENT_PAYMENT_STATUS_NOT_PAYMENT },
    { text: '一部入金', value: AGENT_PAYMENT_STATUS_PART_PAYMENT },
    { text: '入金済', value: AGENT_PAYMENT_STATUS_PAYMENT },
    { text: '過入金', value: AGENT_PAYMENT_STATUS_OVER_PAYMENT },
    { text: '後日請求', value: AGENT_PAYMENT_STATUS_LATER_DAY },
    { text: '請求なし', value: AGENT_PAYMENT_STATUS_NO_INVOICE },
]

// 代理店決済区分
export const AGENT_SETTLEMENT_TYPES = [
    { text: '入金', value: AGENT_SETTLEMENT_TYPE_PAYMENT },
    { text: '払戻', value: AGENT_SETTLEMENT_TYPE_REPAYMENT },
]

// 代理店入金方法
export const AGENT_PAYMENT_TYPES = [
    { text: '後日請求', value: AGENT_PAYMENT_TYPE_LATER_DAY },
    { text: '振込', value: AGENT_PAYMENT_TYPE_TRANSFER },
    { text: '現金', value: AGENT_PAYMENT_TYPE_CASH },
    { text: '販売店入金', value: AGENT_PAYMENT_TYPE_AGENT_PAYMENT },
]

// 代理店払戻方法
export const AGENT_REPAYMENT_TYPES = [
    { text: '振込', value: AGENT_REPAYMENT_TYPE_TRANSFER },
    { text: '現金', value: AGENT_REPAYMENT_TYPE_CASH },
]

// 往復
export const ROUND_TRIPS = [
    { text: '往路', value: ROUND_TRIP_OUTWARD },
    { text: '復路', value: ROUND_TRIP_HOMEWARD },
]

// 代理店予約状態
export const AGENT_BOOKING_STATUSES = [
    { text: '予約', value: AGENT_BOOKING_STATUS_BOOKING },
    { text: '予約削除', value: AGENT_BOOKING_STATUS_CANCEL },
]

// プラン部店
export const PLAN_DEPARTMENTS = [
    { text: '宮崎', value: PLAN_DEPARTMENT_MIYAZAKI },
    { text: '神戸', value: PLAN_DEPARTMENT_KOBE },
]

// プラン性別制限
export const PLAN_GENDER_RESTRICTS = [
    { text: '設定なし', value: PLAN_GENDER_RESTRICT_NONE },
    { text: '女性のみ', value: PLAN_GENDER_RESTRICT_FEMALE },
    { text: '男性のみ', value: PLAN_GENDER_RESTRICT_MALE },
]

// プラン片道制限
export const PLAN_ONE_WAY_RESTRICTS = [
    { text: '設定なし', value: PLAN_ONE_WAY_RESTRICT_NOT_SET },
    { text: '設定あり', value: PLAN_ONE_WAY_RESTRICT_SET },
]

// 端数処理
export const ROUNDING_UNITS = [
    { text: '1円', value: ROUNDING_UNIT_1 },
    { text: '10円', value: ROUNDING_UNIT_10 },
    { text: '100円', value: ROUNDING_UNIT_100 },
    { text: '1,000円', value: ROUNDING_UNIT_1000 },
    { text: '10,000円', value: ROUNDING_UNIT_10000 },
]

// 食事精算方法
export const MEAL_PAYMENT_TYPES = [
    { text: '事前精算', value: MEAL_PAYMENT_TYPE_PREVIOUS },
    { text: '当日精算', value: MEAL_PAYMENT_TYPE_TODAY },
    { text: '後日精算', value: MEAL_PAYMENT_TYPE_AFTER },
    { text: 'クーポン精算', value: MEAL_PAYMENT_TYPE_COUPON },
]

// 基準航路
export const STANDARD_ROUTE = [
    { text: '第一基準', value: STANDARD_ROUTE_NO_ONE },
    { text: '第二基準', value: STANDARD_ROUTE_NO_TWO },
]

// 港
export const HARBOR = [
    { text: '宮崎', value: HARBOR_MIYAZAKI },
    { text: '神戸', value: HARBOR_KOBE },
    { text: '細島', value: HARBOR_HOSOSHIMA },
]

// 世代区分
export const GENERATION_TYPES = [
    { text: '現行船', value: GENERATION_TYPE_EXPRESS },
    { text: '新船', value: GENERATION_TYPE_FERRY },
]

// バウチャ出力有無
export const VOUCHER_OUTPUTS = [
    { text: '出力なし', value: VOUCHER_OUTPUT_NONE },
    { text: '一括出力', value: VOUCHER_OUTPUT_BULK },
    { text: '個別出力', value: VOUCHER_OUTPUT_INDIVIDUAL },
]

// ケージ種別
export const CAGE_TYPES = [
    { text: '大型', value: CAGE_TYPES_LARGE },
    { text: '中小型', value: CAGE_TYPES_SMALL },
    { text: '予備', value: CAGE_TYPES_EXTRA },
]

// 掲示区分
export const POST_CATEGORIES = [
    { text: 'お知らせ', value: POST_CATEGORY_NOTICES },
    { text: '注意事項', value: POST_CATEGORY_PRECAUTIONS },
]

// キャンセル計算方法
export const CANCEL_CALCULATION_METHODS = [
    { text: '割合指定', value: CANCEL_CALCULATION_METHODS_RETIO },
    { text: '金額指定', value: CANCEL_CALCULATION_METHODS_AMOUNT },
]

// 発売期間区分
export const SALES_PERIOD_TYPES = [
    { text: '月指定', value: SALES_PERIOD_TYPE_MONTH },
    { text: '日数指定', value: SALES_PERIOD_TYPE_DAYS },
    { text: '期間指定', value: SALES_PERIOD_TYPE_PERIOD },
]

// 性別制限
export const GENDER_RESTRICTS = [
    { text: 'なし', value: GENDER_RESTRICT_NONE },
    { text: '男性のみ', value: GENDER_RESTRICT_MALE },
    { text: '女性のみ', value: GENDER_RESTRICT_FEMALE },
]

// 車両制限
export const VEHICLE_RESTRICTS = [
    { text: 'なし', value: VEHICLE_RESTRICT_NONE },
    { text: '車のみ', value: VEHICLE_RESTRICT_CAR },
    { text: 'バイクのみ', value: VEHICLE_RESTRICT_TWO_AXLE_CAR },
    { text: '人のみ', value: VEHICLE_RESTRICT_PASSENGER },
]

// お子様連れ制限
export const CHILD_RESTRICTS = [
    { text: 'なし', value: CHILD_RESTRICT_NONE },
    { text: '小人', value: CHILD_RESTRICT_CHILD },
    { text: '小人または乳幼児', value: CHILD_RESTRICT_CHILD_OR_INFANT },
]

// 決済方法制限
export const COUNTER_PAYMENT_RESTRICTS = [
    { text: '事前決済（クレカ、コンビニ、Pay-easy）', value: COUNTER_PAYMENT_RESTRICT_ADVANCE },
    { text: '事前決済（クレジットカード）', value: COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT },
    { text: '事前決済（コンビニ、Pay-easy）', value: COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY },
    { text: '当日決済', value: COUNTER_PAYMENT_RESTRICT_COUNTER },
    { text: '事前（クレカ、コンビニ、Pay-easy）・当日決済', value: COUNTER_PAYMENT_RESTRICT_ADVANCE_COUNTER },
    { text: '事前（クレジットカード）・当日決済', value: COUNTER_PAYMENT_RESTRICT_ADVANCE_CREDIT_COUNTER },
    { text: '事前（コンビニ、Pay-easy）・当日決済', value: COUNTER_PAYMENT_RESTRICT_ADVANCE_CVS_PAYEASY_COUNTER },
]

// 決済結果
export const AUTHORI_RESULTS = [
    { text: '未決済', value: AUTHORI_RESULT_UNPROCESSED },
    { text: '未決済(3DS登録済)', value: AUTHORI_RESULT_AUTHENTICATED },
    { text: '有効性チェック', value: AUTHORI_RESULT_CHECK },
    { text: '即時売上', value: AUTHORI_RESULT_CAPTURE },
    { text: '仮売上', value: AUTHORI_RESULT_AUTH },
    { text: '実売上', value: AUTHORI_RESULT_SALES },
    { text: '取消', value: AUTHORI_RESULT_VOID },
    { text: '返品', value: AUTHORI_RESULT_RETURN },
    { text: '月跨り返品', value: AUTHORI_RESULT_RETURNX },
    { text: '簡易オーソリ', value: AUTHORI_RESULT_SAUTH },
]

// オーソリ決済種別
export const AUTHORI_PAYMENT_METHODS = [
    { text: 'クレジットカード', value: AUTHORI_PAYMENT_METHOD_CREDIT },
    { text: 'Pay-easy', value: AUTHORI_PAYMENT_METHOD_PAY_EASY },
    { text: 'コンビニ払い', value: AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE },
]

// お知らせ区分
export const INFORMATION_TYPES = [
    { text: '共通', value: INFORMATION_TYPE_COMMON },
    { text: '航路毎', value: INFORMATION_TYPE_SEA_ROUTE },
]

// 人格区分
export const PERSONALITY_TYPES = [
    { text: '個人', value: PERSONALITY_TYPE_INDIVIDUAL },
    { text: '法人', value: PERSONALITY_TYPE_CORPORATION },
]

// コンビニ区分
export const CONVENIENCE_TYPES = [
    { text: 'セブンイレブン', value: CONVENIENCE_TYPE_SEVEN_ELEVEN },
    { text: 'ローソン', value: CONVENIENCE_TYPE_LAWSON },
    { text: 'ファミリーマート', value: CONVENIENCE_TYPE_FAMILY_MART },
    { text: 'ミニストップ', value: CONVENIENCE_TYPE_MINI_STOP },
    { text: 'セイコーマート', value: CONVENIENCE_TYPE_SEICOMART },
]

// 取引内容
export const TRANSACTION_TYPES = [
    { text: '予約', value: TRANSACTION_TYPE_BOOKING },
    { text: '変更', value: TRANSACTION_TYPE_CHANGE },
    { text: '取り消し', value: TRANSACTION_TYPE_CANCEL },
]

// メールアドレス状態
export const EMAIL_STATUS = [
    { text: '仮登録', value: EMAIL_STATUS_UNVERIFIED },
    { text: '登録済', value: EMAIL_STATUS_VERIFIED },
]

// 規約区分
export const TERMS_TYPES = [
    { text: '利用規約', value: TERMS_TYPE_TERMS },
    { text: 'プライバシーポリシー', value: TERMS_TYPE_PRIVACY_POLICY },
]

// テキスト→値変換
export function convertValue(array: any, text: string) {
    return array.find((obj: any) => {
        return obj.text === text
    }).value
}

// 値→テキスト変換
export function convertText(array: any, value: number | string | undefined | null) {
    if (value === undefined || value === null) {
        return ''
    }
    const target_obj = array.find((obj: any) => {
        return obj.value === value
    })
    return isNull(target_obj) ? '' : target_obj.text
}
export function convertTexts(array: any, value: string) {
    if (value === undefined || value === null) {
        return ''
    }
    const values = [] as string[]
    for (let i = 0; i < value.split(',').length; i++) {
        values.push(
            array.find((obj: any) => {
                return obj.value === value.split(',')[i]
            }).text,
        )
    }
    return values.join('、')
}
